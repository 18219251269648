@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,300i,400,500,700');
@import url('dubai-font.css');
@import url('neosansstd-font.css');
@import url('ocraext-font.css');

* {
    font-family: 'Roboto', sans-serif;
    outline: none;
}
.--rtl * {
    font-family: 'Dubai', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.clearfix {
    zoom: 1;
}
.clearfix:before,
.clearfix:after {
    content: '';
    display: table;
}
.clearfix:after {
    clear: both;
}

.app-loading {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; 
}
.app-loading__inner {
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.app-loading__error {
    margin: 30px 0 0 0;
    font-size: 18px;
    font-weight: 300;
    color: #F00;
    line-height: 150%;
}

.app-error {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; 
}
.app-error__inner {
    padding: 20px;
    background: #b48c24;
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    position: relative;
    border-radius: 30px;
}
.app-error__icon {
    position: absolute;
    top: 0;
    left: 0;
    color: #002652;
    translate: -25% -25%;
    background: #FFF;
    padding: 0 20px 20px 0;
}
.app-error__logo {
    display: block;
    margin-bottom: 30px;
}
.app-error__hr {
    width: 200px;
    margin: 0 0 20px 0;
}
.app-error__text {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    color: #FFF;
    line-height: 150%;
    text-align: center;
}

#root {
    position: relative;
}
#root::after {
    display: none;
    position: fixed;
    content: 'Beta';
    color: #FFF;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 400;
    padding: 5px;
    text-align: center;
    background: #b48c24;
    bottom: -33px;
    right: -250px;
    transform: rotate(-45deg) translate(0, -75px);
    transform-origin: top center;
    width: 500px;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.page {
    background: #fff;
    overflow-x: hidden;
}

.header__top {
    background-position: bottom center;
    background-size: cover;
    position: relative;
}
.header--discount .header__top {
    margin-bottom: 15px;
    background-image: url(../img/header-background.jpg);
}
.header--gold .header__top {
    background-image: url(../img/header-background-gold.jpg);
}
.header--platinum .header__top {
    background-image: url(../img/header-background-platinum.jpg);
}
.header--silver .header__top {
    background-image: url(../img/header-background-silver.jpg);
}
.header--discount .header__top:after {
    content: '';
    position: absolute;
    height: 25px;
    bottom: -12px;
    left: 0;
    right: 0;
    background-image: url(../img/header-background-stripe.png);
    background-repeat: repeat-x;
}
.header__heading {
    display: flex;
    position: relative;
    padding: 15px 0;
}
.header--discount .header__heading {
    background-image: url(../img/header-top-background.jpg);
    background-position: top left;
    background-size: cover;
}
.header__heading::after {
/*
    content: '';
    position: absolute;
    left: 3px;
    right: 3px;
    bottom: 0px;
    height: 2px;
    background: #fff;
*/
}
.header__menus {
    margin-left: auto;
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.--rtl .header__menus {
    margin-left: 35px;
    margin-right: auto;
}
.header__hamad {
    display: flex;
    justify-content: center;
    position: relative;
    /*overflow: hidden;*/
    padding-top: calc((15 / 64) * 100%);
}
.header__hamad--no-news {
    padding: 0;
}

.logo {
    margin-left: 35px;
}
.--rtl .logo {
    margin-left: 0;
    margin-right: 35px;
}
.logo__image {
    display: block;
    height: 120px;
}
.logo__image--narrow {
    display: none;
}

.user-menu {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 10px 0 0 0;
    align-items: stretch; /* ? */
}
.user-menu__item {
    margin-right: 30px;
    padding-left: 30px;
    position: relative;
    display: flex;
    align-items: center;
}
.--rtl .user-menu__item {
    margin-right: 0;
    margin-left: 30px;
    padding-left: 0;
    padding-right: 30px;
}
.user-menu__item:last-child {
    margin-right: 0;
    padding-right: 30px;
}
.--rtl .user-menu__item:last-child {
    padding-left: 30px;
    margin-left: 0;
}
.user-menu__item::before {
    position: absolute;
    content: '';
    left: 0;
    display: block;
    width: 1px;
    height: 100%;
    background: #fff;
}
.--rtl .user-menu__item::before {
    left: auto;
    right: 0;
}
.user-menu__item:last-child::after {
    position: absolute;
    content: '';
    right: 0;
    display: block;
    width: 1px;
    height: 100%;
    background: #fff;
}
.--rtl .user-menu__item:last-child::after {
    right: auto;
    left: 0;
}
.user-menu__item--dropdown {
    position: relative;   
}
.user-menu__button {
    padding: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.user-menu__button-text {
    font-weight: 300;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    margin-left: 10px;
}
.--rtl .user-menu__button-text {
    margin-left: 0;
    margin-right: 10px;
}
.user-menu__link,
.user-menu__dropdown {
    font-weight: 300;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.--rtl .user-menu__link,
.--rtl .user-menu__dropdown {
    /*font-weight: 400;*/
}
.user-menu__dropdown {
    position: relative;
    margin-right: 25px;
}
.--rtl .user-menu__dropdown {
    margin-right: 0;
    margin-left: 25px;
}
.user-menu__dropdown::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #FFF;
    position: absolute;
    top: 11px;
    right: -25px;
}
.--rtl .user-menu__dropdown::after {
    right: auto;
    left: -25px;
}
.user-menu__item--dropdown-open .user-menu__dropdown::after {
    border-top: none;
    border-bottom: 5px solid #FFF;
}
.user-menu__image--blue {
    display: none;
}
.user-menu__mobile-text {
    display: none;
}
.user-menu__cart {
    margin-left: 10px;
}
.--rtl .user-menu__cart {
    margin-left: 0;
    margin-right: 10px;
}
.user-menu__dropdown-items {
    display: none;
    position: absolute;
    top: 34px;
    right: 0;
    list-style-type: none;
    padding: 0;
    margin: 0;
    background: #FFF;
    padding: 10px 0;
    z-index: 10;
}
.--rtl .user-menu__dropdown-items {
    right: auto;
    left: 0;
}
.user-menu__item--dropdown-open .user-menu__dropdown-items {
    display: block;
}
.user-menu__dropdown-item {
    cursor: pointer;
    padding: 10px 20px;
    color: #333;
    white-space: nowrap;
}
.user-menu__dropdown-item:hover {
    background: #EEE;
}

.member-menu {
    display: flex;
    padding: 0;
    margin: 0 0 10px 0;
    list-style-type: none;
}
.member-menu__item {
    margin-right: 10px;
}
.--rtl .member-menu__item {
    margin-right: 0;
    margin-left: 10px;
}
.member-menu__item:last-child {
    margin-right: 0;
}
.--rtl .member-menu__item:last-child {
    margin-left: 0;
}
.member-menu__link {
    display: block;
    text-decoration: none;
    /*font-weight: 100;*/
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 20px;
}
.--rtl .member-menu__link {
    font-weight: 400;
}
.header--discount .member-menu__link {
    background: #b48c24;
    color: #fff;
}
.header--gold .member-menu__link {
    background: #002652;
    color: #fff;
}
.header--platinum .member-menu__link {
    background: #b48c24;
    color: #fff;
}
.header--silver .member-menu__link {
    background: #002652;
    color: #fff;
}

.main-menu {
    background: #fff;
    padding: 20px 0;
}
.main-menu__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
}
.main-menu__item {
    display: flex;
    align-items: center;
    padding-right: 25px;
    position: relative;
}
.--rtl .main-menu__item {
    padding-right: 0;
    padding-left: 25px;
}
.main-menu__item::before {
    content: '';
    border-left: 1px solid #babcc2;
    height: 25px;
    display: block;
}
.--rtl .main-menu__item::before {
    border-left: 0;
    border-right: 1px solid #babcc2;
}
.main-menu__item:first-child::before {
    display: none;
}
.main-menu__link {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    color: #002752;
    margin-left: 25px;
}
.--rtl .main-menu__link {
    margin-left: 0;
    margin-right: 25px;
}
.main-menu__link--active::after,
.main-menu__link:hover::after {
    content: '';
    display: block;
    height: 2px;
    background: #846826;
    left: 20px;
    bottom: -5px;
    right: 20px;
    position: absolute;
}

.hamad {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    transform: translateX(-50%) translateY(-50%);
}
.header__hamad--no-news .hamad {
    position: static;
    transform: none;
    padding: 30px 0;
}
.hamad--nochat {
    padding: 30px 0;
}
.hamad--hide {
    opacity: 0;
    pointer-events: none;
}
.hamad__chat {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hamad__icon-wrap {
    position: relative;
    margin-right: 25px;
}
.--rtl .hamad__icon-wrap {
    margin-right: 0;
    margin-left: 25px;
}
.hamad__icon-wrap::after {
    content: '';
    position: absolute;
/*
    background-image: url(../img/icon-hamad-shadow.png);
    background-repeat: no-repeat;
    background-position: center center;
    width: 80px;
    height: 7px;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
*/
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
    right: -10px;
    top: 35px;
}
.--rtl .hamad__icon-wrap::after {
    left: -10px;
    right: initial;
    border-left: 0;
    border-right: 10px solid #fff;
}
.hamad__icon {
    width: 125px;
    height: 125px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #fff;
}
.header--discount .hamad__icon {
/*
    background: #00244d;
*/
}
.header--gold .hamad__icon {
/*
    background: #e6e6e6;
*/
}
.header--platinum .hamad__icon {
/*
    background: #e6e6e6;
*/
}
.header--silver .hamad__icon {
/*
    background: #e6e6e6;
*/
}
.hamad__image {
    margin-top: 5px;
    display: block;
    width: 85px;
}
.hamad__bubble {
    padding: 20px;
    border-radius: 10px;
    position: relative;
    z-index: 0;
    min-width: 550px;
}
.header--discount .hamad__bubble {
    background: #00244d;
}
.header--gold .hamad__bubble {
    background: #fff;
}
.header--platinum .hamad__bubble {
    background: #fff;
}
.header--silver .hamad__bubble {
    background: #fff;
}
.hamad__bubble::before {
    position: absolute;
    content: '';
    display: block;
    width: 107px;
    height: 42px;
    left: -40px;
    top: 13px;
    z-index: -1;
    visibility: hidden;
}
.--rtl .hamad__bubble::before {
    left: auto;
    right: -40px;
    transform: rotateY(180deg);
}
.header--discount .hamad__bubble::before {
    background-image: url(../img/chat-bubble-part.png);
}
.header--gold .hamad__bubble::before {
    background-image: url(../img/chat-bubble-part-white.png);
}
.header--platinum .hamad__bubble::before {
    background-image: url(../img/chat-bubble-part-white.png);
}
.header--silver .hamad__bubble::before {
    background-image: url(../img/chat-bubble-part-white.png);
}
.hamad__bubble::after {
/*
    position: absolute;
    content: '';
    display: block;
    width: 883px;
    height: 18px;
    background-image: url(../img/chat-bubble-shadow.png);
    background-size: contain;
    background-repeat: no-repeat;
    left: 50%;
    transform: translateX(-50%);
    bottom: -25px;
    opacity: 0.5;
    max-width: 120%;
*/
}
.hamad__text {
    margin: 0;
}
.hamad__text-link {
    text-decoration: none;
    color: #85723c;
    font-weight: 700;
    font-size: 26px;
    display: block;
    /*margin-bottom: 10px;*/
}
.hamad__text-desc {
    color: #002752;
    font-size: 22px;
    font-weight: 400;
    display: block;
}
.header--discount .hamad__text,
.header--discount .hamad__text-link {
    /*color: #ac7c23;*/
    color: #FFF;
}
.header--gold .hamad__text,
.header--gold .hamad__text-link {
    color: #ac7c23;
}
.header--platinum .hamad__text,
.header--platinum .hamad__text-link {
    color: #ac7c23;
}
.header--silver .hamad__text,
.header--silver .hamad__text-link {
    color: #ac7c23;
}
.hamad__link {
    text-decoration: underline;
    display: block;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    margin-top: 5px;
}
.--rtl .hamad__link {
    text-align: right;
}
.header--discount .hamad__link {
    color: #fff;
}
.header--gold .hamad__link {
    color: #002652;
}
.header--platinum .hamad__link {
    color: #002652;
}
.header--silver .hamad__link {
    color: #002652;
}
.hamad__search {
    position: relative;
}
.hamad__search--wrapper {
    position: relative;
}
.hamad__search-button {
    border: 0;
    background-color: transparent;
    padding: 0;
    content: '';
    position: absolute;
    width: 50px;
    height: 25px;
    display: block;
    top: 0;
    right: 20px;
    background-image: url(../img/header-icon-search-2.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 20px;
    border-left: 2px solid #a99b75;
    cursor: pointer;
    opacity: 0.5;
}
.--rtl .hamad__search-button {
    right: auto;
    left: 20px;
    padding-left: 0;
    padding-right: 20px;
    border-left: 0;
    border-right: 2px solid #a99b75;
    background-position: left center;
}
.hamad__input {
    border: 0;
    display: block;
    width: 100%;
    min-width: 750px;
    font-size: 20px;
    padding: 12px 100px 12px 30px;
    border-radius: 10px;
}
.--rtl .hamad__input {
    padding: 12px 30px 12px 100px;
}
.header--discount .hamad__input {
    background: #00244d;
    color: #fff;
}
.header--discount .hamad__input::placeholder {
    color: #FFF;
}
.header--gold .hamad__input {
    background: #fff;
    color: #002652;
}
.header--platinum .hamad__input {
    background: #fff;
    color: #002652;
}
.header--silver .hamad__input {
    background: #fff;
    color: #002652;
}
.hamad__quick-search {
    position: absolute;
    left: 0;
    right: 0;
    top: 52px;
}
.hamad__loading {
    position: absolute;
    top: 13px;
    right: 80px;
}

.quick-search {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    z-index: 100;
    overflow: hidden;
    max-width: 100%;
}
.quick-search__list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.quick-search__item {
    display: flex;
    flex-direction: row;
    padding: 10px 30px;
    align-items: center;
    cursor: pointer;
    flex-wrap: nowrap;
}
.quick-search__item--selected {
    background: #eee;
}
.quick-search__tag {
    background: #ddd;
    padding: 5px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-right: 5px;
    font-size: 10px;
    font-weight: 500;
    color: #666;
}
.quick-search__text {
    color: #002652;
    font-size: 16px;
    font-weight: 400;
}
.quick-search__text--additional {
    color: #999;
    margin-left: 10px;
    white-space: nowrap;
    flex: 1 1;
    text-overflow: ellipsis;
    overflow: hidden;
}
.quick-search__highlight {
    font-weight: 600;
    background-color: transparent;
    text-decoration: underline;
}
.quick-search__icon {
    margin-left: auto;
    font-size: 20px;
    color: #002652;
}

.header__mobile {
    display: none;
}

.hamad-news {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
}
.hamad-news .swiper-container {
    top: 50%;
    transform: translateY(-50%);
}
.hamad-news__link {
    display: block;
}
.hamad-news__image {
    display: block;
    width: 100%;
}
.hamad-news__image--mobile {
    display: none;
}
.hamad-news__arrow {
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    position: absolute;
    padding: 10px;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
}
.hamad-news__arrow--left {
    left: 0;
}
.hamad-news__arrow--right {
    right: 0;
}
.hamad-news__arrow-image {
    width: 35px;
    height: auto;
    display: block;
}

.categories {
    background: #f7f7f7;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.10);
    z-index: 1;
    position: relative;
    padding: 25px 55px;
    margin-bottom: 30px;
}
.categories--estore {
    background: transparent;
    box-shadow: none;
}
.categories__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}
.categories__image-wrap {
    width: 125px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 6px solid #d5d9df;
    border-radius: 50%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.10);
    background: #fdfcfc;
}
.categories__image {
    display: block;
    width: 75px;
    height: 75px;
}
.categories__name {
    margin: 20px 0 0 0;
    padding: 0 10px;
    color: #192c56;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
}
.categories__arrow {
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    position: absolute;
    padding: 10px;
}
.categories__arrow--left {
    left: 0;
}
.categories__arrow--right {
    right: 0;
}
.categories__arrow-image {
    width: 35px;
    height: auto;
    display: block;
}

.slider {
    padding: 30px 15px 30px;
}
.slider--white {
    background: #fff;
}
.slider--gray {
    background: #e9e9e9;
}
.slider--gold {
    background: #b48c24;
}
.slider__header {
    display: flex;
    padding: 0 20px;
}
.slider__title {
    margin: 0 20px 20px 0;
    display: flex;
    align-items: center;
    flex: 1;
}
.--rtl .slider__title {
    margin: 0 0 20px 20px;
}
.slider__title::after {
    content: '';
    height: 1px;
    background: #002652;
    margin-left: 25px;
    flex: 0 1 250px;
}
.--rtl .slider__title::after {
    margin-left: 0;
    margin-right: 25px;
}
.slider__title-text {
    font-size: 35px;
    font-weight: 400;
    /*text-transform: uppercase;*/
    color: #002752;
}
/*
.slider--white .slider__title {
    color: #b48c24;
}
.slider--gray .slider__title {
    color: #002652;
}
.slider--gold .slider__title {
    color: #fff;
}
*/
.slider__wrapper {
    position: relative;
}
.slider--services .swiper-slide {
    width: calc((100% - 40px) / 4.5);
}
.slider--offers .swiper-slide {
    width: calc((100% - 50px) / 5.5);
}
.slider--products .swiper-slide {
    width: calc((100% - 50px) / 5.5);
}
.slider--vouchers .swiper-slide {
    width: calc((100% - 50px) / 5.5);
}
.slider__item {
    position: relative;
}
.slider__link {
    display: block;
    text-decoration: none;
    position: relative;
}
.slider--hover .slider__link:not(.slider__link--more):hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
}
.slider--offers .slider__link:not(.slider__link--more):hover::before {
    background: rgba(0, 38, 82, 0.75);
}
.slider--products .slider__link:hover::before {
    background: rgba(180, 140, 36, 0.75);
}
.slider--vouchers .slider__link:hover::before {
    background: rgba(0, 38, 82, 0.75);
}
.slider--hover .slider__link:not(.slider__link--more):hover::after {
    content: 'View detail';
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    color: #fff;
    text-decoration: none;
    z-index: 6;
}
.--rtl .slider--hover .slider__link:not(.slider__link--more):hover::after {
    content: 'عرض التفاصيل';
}
.slider__link--more {
    padding-top: calc((478 / 640) * 100%);
    border: 1px solid #e7e7e7;
}
.slider__more-text {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    font-size: 24px;
    color: #002652;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.slider__image-wrap {
    position: relative;
}
.slider__image {
    display: block;
    width: 100%;
    height: auto;
}
.slider--white .slider__image {
/*
    border-left: 1px solid #b48c24;
    border-right: 1px solid #b48c24;
    border-top: 1px solid #b48c24;
*/
    border: 1px solid #e7e7e7;
}
.slider--services .slider__image {
    border: 0;
}
.slider__image-desc {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.slider__image-desc-text {
    margin: 0;
    font-size: 25px;
    font-weight: 400;
    color: #fff;
    padding: 20px;
}
.slider__meta {
    display: flex;
    align-items: center;
    padding: 10px 15px;
}
.slider--white .slider__meta {
/*
    background: #b48c24;
    color: #fff;
*/
    color: #002752;
}
.slider--gray .slider__meta {
    background: #002652;
    color: #fff;
}
.slider--gold .slider__meta {
    background: #fff;
    color: #002652;
}
.slider--services .slider__meta {
    background: #b48c24;
    padding: 20px;
}
.slider__info {
    margin-right: 10px;
    overflow: hidden;
}
.--rtl .slider__info {
    margin-right: 0;
    margin-left: 10px;
}
.slider__name {
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    /*text-transform: uppercase;*/
    margin: 0;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.slider__partner {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    /*text-transform: uppercase;*/
    padding-bottom: 5px;
    margin-bottom: 5px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.slider__partner::after {
    content: '';
    display: block;
    width: 200px;
    max-width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    position: absolute;
}
.--rtl .slider__partner::after {
    left: auto;
    right: 0;
}
.slider--white .slider__partner::after {
/*
    background: #fff;
*/
    background: #002752;    
}
.slider--gray .slider__partner::after {
    background: #b48c24;
}
.slider--gold .slider__partner::after {
    background: #002652;
}
.slider__text {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.slider__discount {
    font-size: 42px;
    font-weight: 300;
    white-space: nowrap;
    margin-left: auto;
}
.--rtl .slider__discount {
    margin-left: 0;
    margin-right: auto;
}
.slider__currency {
    font-size: 18px;
}
.slider__left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px 15px;
    cursor: pointer;
    visibility: hidden;
}
.slider__right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px 15px;
    cursor: pointer;
    visibility: hidden;
}
.slider__arrow {
    display: block;
    height: 80px;
}
.slider__buttons {
    text-align: center;
}
.slider__icon-wrapper {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 10px;
    border-radius: 50%;
    background-color: #b48c24;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}
.--rtl .slider__icon-wrapper {
    right: auto;
    left: 15px;
}
.slider__icon {
    color: #FFF;
    font-size: 16px;
}

.selection {
    padding: 50px 0;
    background: #002652;
}
.selection__items {
    display: flex;
    width: 1200px;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
}
.selection__item {
    padding: 0;
    margin-bottom: 30px;
    flex: 1 0 20%;
    min-width: 20%;
    max-width: 20%;
}
.selection__link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.selection__circle {
    background: #fff;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}
.selection__icon {
    display: block;
    width: 90px;
    height: 90px;
}
.selection__title {
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 0 15px;
    margin: 0;
}
.selection__buttons {
    text-align: center;
}

.section {
    padding: 35px 15px 80px;
}
.section--gray {
    background: #e9e9e9;
}
.section--white {
    background: #FFF;
}
.section--with-component {
    padding-bottom: 30px !important;
}
.section--with-component-weld {
    padding-bottom: 0 !important;
}
.section--no-top-padding {
    padding-top: 0 !important;
}
.section__title {
    font-size: 50px;
    font-weight: 300;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}
.section__title--center {
    text-align: center;
}
.section__title--no-transform {
    text-transform: none;
}
.section--gray .section__title {
    color: #002652;
}
.section--white .section__title {
    color: #b48c24;
}
.section__subtitle {
    margin: 0 0 20px 0;
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
    color: #002652;
}
.section__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section__text {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    line-height: 150%;
}
.section__text:last-child {
    margin-bottom: 0;
}
.section__text--lead {
    font-size: 24px;
}
.section__text--center {
    text-align: center;
}
.section__text-highlight {
    margin-right: auto;
    font-size: 32px;
    font-weight: 300;
}
.section__text-highlight--blue {
    color: #002652;
}
.section__text-highlight--yellow {
    color: #b48c24;
}
.section__text--flex {
    display: flex;
}
.section__link {
    text-decoration: none;
    color: #002652;
    font-weight: 400;
}
.section__link--gold {
    color: #ac7c23;
}
.section__link--underline {
    text-decoration: underline;
}
.section__link-icon {
    margin-right: 5px;
}
.section__columns {
    display: flex;
}
.section__columns--margin-bottom {
    margin-bottom: 20px;
}
.section__columns--margin-bottom:last-child {
    margin-bottom: 0;
}
.section__column {
    flex: 1;
    margin-right: 30px;
    overflow: hidden;
}
.--rtl .section__column {
    margin-right: 0;
    margin-left: 30px;
}
.section__column--minimum {
    flex-basis: auto;
    flex-grow: 0;
}
.section__column--wide {
    flex: 2;
}
.section__column--center {
    text-align: center;
}
.section__column:last-child {
    margin-right: 0;
}
.--rtl .section__column:last-child {
    margin-left: 0;
}
.section__dl {
    margin: 0 0 20px 0;
}
.section__dl:last-child {
    margin-bottom: 0;
}
.section__dl--boxed {
    padding: 20px;
    background: #eee;
}
.section__dt {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}
.section__dd {
    margin: 0 0 20px 0;
    color: #333;
}
.section__dd--code {
    font-family: monospace;
}
.section__dd:last-child {
    margin-bottom: 0;
}

.signin {
    display: flex;
    background: #FFF;
    max-width: 100%;
    padding: 50px;
}
.signin__form {
    margin-right: 50px;
    max-width: 100%;
    width: 400px;
}
.--rtl .signin__form {
    margin-right: 0;
    margin-left: 50px;
}
.signin__form--uaepass {
    width: auto;
    margin: 0 0 30px 0;
}
.--rtl .signin__form--uaepass {
    margin-left: 0;
}
.signin__options {
    padding-left: 50px;
    border-left: 1px solid #002652;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.--rtl .signin__options {
    padding-left: 0;
    padding-right: 50px;
    border-left: 0;
    border-right: 1px solid #002652;
}
.signin__options-or {
    position: absolute;
    background: #fff;
    color: #002652;
    display: block;
    padding: 10px;
    top: 50%;
    left: 0;
    translate: -50% -50%;
}
.--rtl .signin__options-or {
    left: auto;
    right: 0;
    translate: 50% -50%;
}
.signin__links {
    padding: 0;
    margin: 30px 0 0 0;
    list-style-type: none;
    text-align: center;
}
.signin__links--no-margin {
    margin-top: 0;
}
.signin__link-item {
    margin-bottom: 10px;
    display: inline-block;
}
.signin__link-item:last-child {
    margin-bottom: 0;
}
.signin__link {
    display: block;
    width: auto;
    text-align: center;
}
.signin__user-select {
    width: 400px;
    max-width: 100%;
}
.signin__users {
    margin: 0 0 20px 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signin__user {
    cursor: pointer;
    padding: 10px 20px;
    background: #eee;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    background-color: #b48c24;
    align-items: center;
}
.signin__user:last-child {
    margin-bottom: 0;
}
.signin__user-icon {
    font-size: 32px;
    margin-right: 20px;
    color: #fff;
}
.signin__user-info {

}
.signin__user-ident {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    color: #eee;
}
.signin__user-id {
    display: block;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}
.signin__user-current {
    margin-bottom: 20px;
}
.signin__user-current-text {
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    color: #333;
}
.signin__user-current-info {
    background: #eee;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.signin__user-current-icon {
    font-size: 32px;
    color: #846826;
    margin-right: 20px;
}
.signin__user-current-details {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.signin__user-current-name {
    font-weight: 700;
    color: #333;
    font-size: 18px;
}
.signin__user-current-id {
    color: #333;
}
.signin__uaepass-wrapper {
    width: 800px;
    max-width: 100%;
}
.signin__uaepass-question {
    text-align: center;
    font-size: 24px;
    margin: 0 0 30px 0;
    color: #001b39;
}
.signin__uaepass-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
}
.signin__uaepass-buttons button {
    margin-right: 30px;
}
.--rtl .signin__uaepass-buttons button {
    margin-left: 30px;
}
.signin__uaepass-buttons button:last-child {
    margin-right: 0;
}
.--rtl .signin__uaepass-buttons button:last-child {
    margin-left: 0;
}
.signin__uaepass-footnotes {
    margin: 0 auto 30px;
    text-align: center;
}
.signin__uaepass-footnote {
    margin: 0 0 20px 0;
    font-size: 18px;
    color: #333;
}
.signin__uaepass-note {
    text-align: center;
    font-size: 16px;
    color: #b48c24;
    margin: 0 0 30px 0;
}
.signin__uaepass-note:last-child {
    margin-bottom: 0;
}
.signin__uaepass-no-account {
    margin: 0 0 20px 0;
    font-size: 16px;
    color: #F00;
}
.signin__uaepass-please-login {
    margin: 0 0 30px 0;
    font-size: 16px;
    color: #b48c24;
    text-align: center;
}
.signin__uaepass-register {
    margin: 0;
    font-size: 18px;
    color: #333;
    text-align: center;
}

.uaepass-button {
    border: 1px solid #666;
    border-radius: 25px;
    margin: 0;
    height: 50px;
    padding: 0 15px;
    background: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.uaepass-button--no-border {
    border: 0;
}
.uaepass-button__image {
    display: inline-block;
    margin-right: 10px;
}
.uaepass-button__text {
    font-size: 20px;
    font-weight: 400;
    color: #333;
    text-transform: uppercase;
}

.signup-progress {
    display: flex;
    margin-bottom: 30px;
    position: relative;
}
.signup-progress::before {
    content: '';
    display: block;
    height: 5px;
    left: 10%;
    top: 12px;
    width: 80%;
    background: #002652;
    position: absolute;
}
.--rtl .signup-progress::before {
    left: auto;
    right: 10%;
}
.signup-progress::after {
    content: '';
    display: block;
    height: 5px;
    left: 10%;
    top: 12px;
    width: 0;
    background: #b48c24;
    position: absolute;
    transition: width 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.--rtl .signup-progress::after {
    left: auto;
    right: 10%;
}
.signup-progress--step-2::after {
    width: 20%;
}
.signup-progress--step-3::after {
    width: 40%;
}
.signup-progress--step-4::after {
    width: 60%;
}
.signup-progress--step-5::after {
    width: 80%;
}
.signup-progress__step {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 20%;
    z-index: 2;
}
.signup-progress__number {
    margin-bottom: 10px;
    width: 30px;
    height: 30px;
    background: #002652;
    color: #FFF;
    border-radius: 50%;
    line-height: 30px;
    transition: background-color 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transition-delay: 0.3s;
}
.signup-progress__step--active .signup-progress__number {
    background: #b48c24;
}
.signup-progress__text {
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
}

.signup {
    display: flex;
    background: #FFF;
    max-width: 100%;
    padding: 50px;
    flex-direction: column;
}
.signup__company {
    margin-bottom: 20px;
}
.signup__company-name {
    margin: 0;
    font-size: 36px;
    font-weight: 300;
    text-transform: uppercase;
    color: #002652;
    text-align: center;
}
.--rtl .signup__company-name {
    text-align: left;
}
.signup__form {
    max-width: 100%;
    width: 1000px;
}
.signup__levels {
    display: flex;
    align-items: flex-start;
}
.signup__level {
    flex: 1;
    max-width: 33%;
    margin-right: 30px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.--rtl .signup__level {
    margin-right: 0;
    margin-left: 30px;
}
.signup__level--selected {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5) !important;
}
.signup__level--discount {
    color: #333;
    background: url('../img/triangles-discount.jpg') no-repeat;
    background-size: cover;
}
.signup__level--silver {
    background: url('../img/triangles-silver.jpg') no-repeat;
    background-size: cover;
    color: #FFF;
}
.signup__level--gold {
    background: url('../img/triangles-gold.jpg') no-repeat;
    background-size: cover;
    color: #FFF;
}
.signup__level--platinum {
    background: url('../img/triangles-platinum.jpg') no-repeat;
    background-size: cover;
    color: #FFF;
}
.signup__level--not-selected {
    opacity: 0.25;
}
.signup__level:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
.signup__level:last-child {
    margin-right: 0;
}
.--rtl .signup__level:last-child {
    margin-left: 0;
}
.signup__level-icon {
    margin-right: 5px;
    display: none;
}
.signup__level--selected .signup__level-icon {
    display: inline-block;
}
.--rtl .signup__level-icon {
    margin-right: 0;
    margin-left: 5px;
}
.signup__level-title {
    text-align: center;
    font-weight: 400;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    padding: 10px;
    font-size: 20px;
}
.signup__level--discount .signup__level-title {
    background: #f0f0f0;
    position: relative;
}
.signup__level--discount .signup__level-title::after {
    content: '';
    height: 8px;
    width: 100%;
    position: absolute;
    bottom: -4px;
    left: 0;
    background: url('../img/header-background-stripe.png') repeat-x;
    background-size: contain;
}
.signup__level--silver .signup__level-title {
    background: #A0A2A2;
}
.signup__level--gold .signup__level-title {
    background: #5B4003;
}
.signup__level--platinum .signup__level-title {
    background: #070606;
}
.signup__level-description.cms-generated ul {
    list-style-type: square;
    margin: 0 20px ;
    padding: 0 0 0 15px;
}
.--rtl .signup__level-description.cms-generated ul {
    padding-left: 0;
    padding-right: 15px;
}
.signup__level-description.cms-generated ul li::before {
    display: none;
}
.signup__level-description.cms-generated li {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 14px;
    font-weight: 300;
}
.signup__level-description.cms-generated li:last-child {
    margin-bottom: 0;
}
.signup__level-price {
    text-align: center;
    font-weight: 300;
    margin: 0 0 20px 0;
}
.signup__level-total {
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
}
.signup__level-total-amount {
    text-align: center;
    margin: 0;
    font-size: 24px;
}
.signup__repeat {
    font-size: 16px;
    margin: 0;
    padding-bottom: 20px;
    text-align: center;
    font-weight: 300;
}
.signup__terms-wrapper {
    border-radius: 20px;
    background: #EEE;
    padding: 20px;
}
.signup__terms {
    overflow: auto;
    max-height: 200px;
}
.signup__terms::-webkit-scrollbar {
    width: 5px;
}
.signup__terms::-webkit-scrollbar-button {
    display: none;
}
.signup__terms::-webkit-scrollbar-thumb {
    background: #CCC;
}
.signup__terms p {
    font-size: 14px !important;
}

.renew {
    display: flex;
    background: #FFF;
    max-width: 100%;
    padding: 50px;
    flex-direction: column;
}
.renew__form {
    max-width: 100%;
    width: 1000px;
}
.renew__level {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    border: 1px solid #DDD;
}
.renew__level-name {
    flex: 1;
}
.--rtl .renew__level-name {
    text-align: right;
}
.renew__level-price {
    flex: 1;
    text-align: right;
}
.--rtl .renew__level-price {
    text-align: left;
}
.renew__total-price {
    flex: 1;
    text-align: right;
}
.--rtl .renew__total-price {
    text-align: left;
}
.renew__level-label {
    font-weight: 300;
    padding: 10px;
    background: #EEE;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.renew__level-value {
    font-size: 20px;
    padding: 10px;
}

.upgrade {
    display: flex;
    background: #FFF;
    max-width: 100%;
    padding: 50px;
    flex-direction: column;
}
.upgrade__form {
    max-width: 100%;
    width: 1000px;
}

.account {
    background: #FFF;
    max-width: 100%;
    width: 80%;
    padding: 50px;
}
.account__level {
    display: flex;
    margin-bottom: 20px;
}
.account__level--discount .account__level-badge {
    background: url('../img/triangles-discount.jpg') no-repeat;
    background-size: cover;
}
.account__level--silver .account__level-badge {
    background: url('../img/triangles-silver.jpg') no-repeat;
    background-size: cover;
}
.account__level--gold .account__level-badge {
    background: url('../img/triangles-gold.jpg') no-repeat;
    background-size: cover;
}
.account__level--platinum .account__level-badge {
    background: url('../img/triangles-platinum.jpg') no-repeat;
    background-size: cover;
}
.account__level-title {
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 20px;
}
.account__level--discount .account__level-title {
    color: #333;
    background: #f0f0f0;
}
.account__level--silver .account__level-title {
    color: #FFF;
    background: #A0A2A2;
}
.account__level--gold .account__level-title {
    color: #FFF;
    background: #5B4003;
}
.account__level--platinum .account__level-title {
    color: #FFF;
    background: #070606;
}
.account__level-info-wrapper {
    padding: 20px;
}
.account__level-info {
    margin: 0;
    font-weight: 300;
    line-height: 150%;
}
.account__level--discount .account__level-info {
    color: #333;
}
.account__level--silver .account__level-info {
    color: #FFF;
}
.account__level--gold .account__level-info {
    color: #FFF;
}
.account__level--platinum .account__level-info {
    color: #FFF;
}
.account__level-info strong {
    font-weight: 400;
}
.account__level-renew {
    margin-top: 20px;
}
.account__level-upgrade {
    margin-top: 20px;
}
.account__controls {
    margin-bottom: 20px;
}
.account__controls:last-child { 
    margin-bottom: 0;
}
.account__controls-button {
    margin-right: 20px;
}
.--rtl .account__controls-button {
    margin-right: 0;
    margin-left: 20px;
}
.account__controls-button:last-child {
    margin-right: 0;
    margin-left: 0;
}
.account__extra-offers {
    margin-bottom: 20px;
}
.account__extra-offers-stats {
    margin-bottom: 20px;
    display: flex;
}
.account__extra-offers-stats-column {
    flex: 1;
    width: calc(100% / 3 - 40px);
    margin-right: 20px;
    border-right: 1px solid #DDD;
    padding-right: 20px;
}
.--rtl .account__extra-offers-stats-column {
    margin-right: 0;
    margin-left: 20px;
    border-right: 0;
    border-left: 1px solid #DDD;
    padding-right: 0;
    padding-left: 20px;
}
.account__extra-offers-stats-column:last-child {
    border-right: 0;
    border-left: 0;
    margin-right: 0;
    margin-left: 0;
}
.account__extra-offers-stats-title {
    font-weight: 400;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.account__extra-offers-stats-value {
    font-size: 24px;
    font-weight: 500;
    color: #002652;
}
.account__orders {
    margin-bottom: 20px;
}
.account__orders-header {
    display: flex;
    background: #EEE;
    border-bottom: 1px solid #DDD;
}
.account__orders-header-column {
    padding: 10px;   
}
.account__orders-header-column--order-id {
    flex: 1
}
.account__orders-header-column--amount {
    width: 100px;
    text-align: right;
}
.account__orders-header-column--status {
    width: 200px;
    text-align: right;
}
.account__orders-row {
    text-decoration: none;
    display: flex;
    border-bottom: 1px dashed #DDD;
}
.account__orders-row:hover {
    background: #F7F7F7;
}
.account__orders-body-column {
    padding: 10px;
}
.account__orders-body-column--order-id {
    flex: 1;
    color: #333;
}
.account__orders-body-column--order-id small {
    color: #666;
}
.account__orders-body-column--amount {
    width: 100px;
    text-align: right;
    color: #002652;
}
.account__orders-body-column--status {
    width: 200px;
    text-align: right;
    color: #b48c24;
    text-overflow: 400;
}
.account__orders-body-column--status small {
    color: #666;
    font-weight: 300;
}
.account__orders-loading {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}
.account__transactions {
    margin-bottom: 20px;
}
.account__transactions-header {
    display: flex;
    background: #EEE;
    border-bottom: 1px solid #DDD;
}
.account__transactions-header-column {
    padding: 10px;   
}
.account__transactions-header-column--transaction-ref {
    flex: 1
}
.account__transactions-header-column--transaction-amount {
    width: 100px;
    text-align: right;
}
.account__transactions-header-column--discount-amount {
    width: 100px;
    text-align: right;
}
.account__transactions-header-column--quantity {
    width: 50px;
    text-align: right;
}
.account__transactions-row {
    text-decoration: none;
    display: flex;
    border-bottom: 1px dashed #DDD;
}
.account__transactions-row:hover {
    background: #F7F7F7;
}
.account__transactions-body-column {
    padding: 10px;
}
.account__transactions-body-column--transaction-ref {
    flex: 1;
    color: #333;
}
.account__transactions-body-column--transaction-ref small {
    color: #666;
}
.account__transactions-body-column--transaction-amount {
    width: 100px;
    text-align: right;
    color: #002652;
}
.account__transactions-body-column--discount-amount {
    width: 100px;
    text-align: right;
    color: #002652;
}
.account__transactions-body-column--quantity {
    width: 50px;
    text-align: right;
    color: #b48c24;
    text-overflow: 400;
}
.account__transactions-loading {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.account-password {
    background: #FFF;
    padding: 50px;
    max-width: 100%;
}
.account-password__form {
    max-width: 100%;
    width: 600px;
}

.account-profile {
    background: #FFF;
    max-width: 100%;
    padding: 50px;
}
.account-profile__form {
    max-width: 100%;
    width: 1000px;
}

.chat {
    background: #FFF;
    padding: 50px;
    max-width: 100%;
}
.chat__form {
    max-width: 100%;
    width: 600px;
}

.unlink {
    background: #FFF;
    padding: 50px;
    max-width: 100%;
}
.unlink__content {
    max-width: 100%;
    width: 600px;
}

.order-page {
    background: #FFF;
    max-width: 100%;
    width: 80%;
    padding: 50px;
}
.order-page__archived {
    padding: 10px;
    color: #FFF;
    background: #002652;
    font-weight: 300;
}

.order-details {
    display: flex;
    border: 1px solid #DDD;
    margin-bottom: 30px;
}
.order-details:last-child {
    margin-bottom: 0;
}
.order-details__item {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 25%;
}
.order-details__heading {
    background: #EEE;
    padding: 10px;
    font-weight: 300;
}
.order-details__content {
    padding: 10px;
    font-size: 20px;
}
.order-details__content--status-processed {
    color: #28a745;
}

.order-voucher {
    border: 1px solid #DDD;
    margin-bottom: 30px;
}
.order-voucher:last-child {
    margin-bottom: 0;
}
.order-voucher__header {
    display: flex;
    border-bottom: 1px solid #DDD;
}
.order-voucher__header-item {
    padding: 10px;
    flex: 1;
}
.order-voucher__header-item--no-flex {
    flex: 0;
    text-align: right;
    padding-left: 100px;
}
.--rtl .order-voucher__header-item--no-flex {
    padding-left: 10px;
    padding-right: 100px;
    text-align: left;
}
.order-voucher__partner {
    margin-bottom: 5px;
}
.order-voucher__title {
    font-size: 20px;
}
.order-voucher__header-label {
    font-weight: 300;
    margin-bottom: 5px;
}
.order-voucher__header-content {
    font-size: 20px;
    white-space: nowrap;
}
.order-voucher__vouchers {
    padding: 10px;
}
.order-voucher__voucher-item {
    padding-bottom: 10px;
    border-bottom: 1px dashed #DDD;
    margin-bottom: 10px;
    display: flex;
}
.order-voucher__voucher-item:last-child {
    padding: 0;
    margin: 0;
    border: 0;
}
.order-voucher__voucher-item-code {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 200px;
}
.--rtl .order-voucher__voucher-item-code {
    margin-right: 0;
    margin-left: 20px;
}
.order-voucher__voucher-item-code-selectable {
    text-align: center;
    display: block;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    width: 100%;
    max-width: 100%;
}
.order-voucher__voucher-item-code button {

}
.order-voucher__voucher-item-meta {
    font-weight: 300;
    line-height: 150%;
}
.order-voucher__pdf {
    align-self: center;
    margin-left: auto;
}
.--rtl .order-voucher__pdf {
    margin-left: 0;
    margin-right: auto;
}
.order-voucher__terms {
    border-top: 1px solid #DDD;
}
.order-voucher__terms-container {
    height: 200px;
    overflow: auto;
    padding: 10px;
}

.order-products {
    border: 1px solid #DDD;
    margin-bottom: 30px;
}
.order-products:last-child {
    margin-bottom: 0;;
}
.order-products__header {
    display: flex;
    border-bottom: 1px solid #DDD;
}
.order-products__header-item {
    padding: 10px;
    flex: 1;
}
.order-products__header-item--no-flex {
    flex: 0;
    text-align: right;
    padding-left: 100px;
}
.--rtl .order-products__header-item--no-flex {
    padding-left: 10px;
    padding-right: 100px;
    text-align: left;
}
.order-products__header-label {
    font-weight: 300;
    margin-bottom: 5px;
}
.order-products__header-content {
    font-size: 20px;
    white-space: nowrap;
}
.order-products__header-content-address {
    font-style: normal;
}

.order-product {
    border-bottom: 1px solid #DDD;
    display: flex;
    align-items: center;
}
.order-product:last-child {
    border-bottom: 0;
}
.order-product__image-wrapper {
    padding: 10px;
}
.order-product__image {
    display: block;
    width: 75px;
    height: auto;
}
.order-product__meta {
    padding: 10px;
    flex: 1;
}
.order-product__title {
    margin-bottom: 10px;
}
.order-product__price {
    font-size: 14px;
}
.order-product__shipping {
    font-size: 14px;
}
.order-product__qty {
    padding: 10px;
    margin-left: auto;
    flex: 0 1 100px;
    text-align: right;
    font-size: 18px;
}
.--rtl .order-product__qty {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
}
.order-product__subtotal {
    padding: 10px;
    flex: 0 1 150px;
    text-align: right;
    font-size: 18px;
}
.--rtl .order-product__subtotal {
    text-align: left;
}

.become-partner {
    display: flex;
    background: #FFF;
    max-width: 100%;
    padding: 50px;
}
.become-partner__form {
    max-width: 100%;
    width: 400px;
}

.cancel-membership {
    display: flex;
    background: #FFF;
    max-width: 100%;
    padding: 50px;
}
.cancel-membership__form {
    max-width: 100%;
    width: 600px;
}
.cancel-membership__terms-wrapper {
    border-radius: 20px;
    background: #EEE;
    padding: 20px;
}
.cancel-membership__terms {
    overflow: auto;
    max-height: 200px;
}
.cancel-membership__terms::-webkit-scrollbar {
    width: 5px;
}
.cancel-membership__terms::-webkit-scrollbar-button {
    display: none;
}
.cancel-membership__terms::-webkit-scrollbar-thumb {
    background: #CCC;
}
.cancel-membership__terms p {
    font-size: 14px !important;
}

.forgot {
    display: flex;
    background: #FFF;
    max-width: 100%;
    padding: 50px;
}
.forgot__form {
    max-width: 100%;
    width: 400px;
}

.button,
.cms-button--gold,
.cms-button--blue {
    display: inline-block;
    border: 0;
    border-radius: 25px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 15px 40px;
    cursor: pointer;
    text-align: center;
}
.cms-button--gold,
.cms-button--blue {
    line-height: 100%;
}
.button--small,
.cms-button--gold,
.cms-button--blue {
    font-size: 16px;
    padding: 10px 25px;
}
.button--white {
    background: #fff;
    color: #002652;
}
.button--blue,
.cms-button--blue {
    background: #002652;
    color: #fff;
}
.button--gold,
.cms-button--gold {
    background: #b48c24;
    color: #fff;
}
.button--gray {
    background: #EEE;
    color: #002652
}
.button--has-loading {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.button--wide {
    width: 100%;
    display: block;
}
.button--disabled {
    background: #DDD;
    pointer-events: none;
}
.button:disabled {
    background: #DDD;
}
.button__icon {
    margin-right: 10px;
}
.--rtl .button__icon {
    margin-right: 0;
    margin-left: 10px;
}
.button__icon--right {
    margin-right: 0;
    margin-left: 10px;
}
.--rtl .button__icon--right {
    margin-right: 10px;
    margin-left: 0;
}
.button__text--short {
    display: none;
}

.form__message {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 150%;
}
.form__message--error {
    color: #F00;
}
.form__message--success {
    color: #0F0;
}
.form__message--center {
    text-align: center;
}
.form__section {
    margin-bottom: 30px;
}
.form__title {
    margin: 0 0 20px 0;
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
    color: #002652;
}
.form__title--center {
    text-align: center;
}
.form__text {
    margin: 0 0 20px 0;
    font-size: 20px;
    font-weight: 300;
    color: #002652;
    line-height: 150%;
}
.form__text--notice {
    padding: 10px;
    background: #eee;
    font-weight: 400;
}
.form__text--center {
    text-align: center;
}
.form__text:last-child {
    margin: 0;
}
.form__section:last-child {
    margin: 0;
}
.form__row {
    display: flex;
    margin: 0 -15px;
}
.form__column {
    flex: 1;
    padding: 0 15px;
    overflow: hidden;
}
.form__column--half {
    max-width: 50%;
}
.form__column--quarter {
    max-width: 25%;
}
.form__column--center {
    margin-left: auto;
    margin-right: auto;
}
.form__column--button-only {
    padding-top: 28px;
}
.form__column--overflow {
    overflow: initial;
}
.form__group {
    margin-bottom: 20px;
}
.signin__form--uaepass .form__group {
    width: 400px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.form__label {
    display: block;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 300;
    color: #b48c24;
}
.form__label--center {
    text-align: center;
}
.form__label-link {
    color: #002652;
}
.form__input-wrap {
    position: relative;
}
.form__input-wrap-icon {
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 20px;
    color: #666;
    cursor: pointer;
}
.--rtl .form__input-wrap-icon {
    right: auto;
    left: 20px;
}
.form__input-wrap-icon:hover {
    opacity: 0.5;
}
.form__input {
    width: 100%;
    display: block;
    padding: 10px 20px;
    color: #002652;
    font-weight: 400;
    font-size: 18px;
    background: #EEE;
    border: 0;
    border-radius: 50px;
}
.form__input:focus {
    background: #DDD;
}
.form__input:disabled {
    opacity: 0.5;
}
.form__input-wrap .form__input {
    padding-right: 60px;
}
.--rtl .form__input-wrap .form__input {
    padding-right: 20px;
    padding-left: 60px;
}
.form__input-help {
    display: block;
    margin-top: 5px;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 300;
    color: #b48c24;
}
.form__textarea {
    width: 100%;
    display: block;
    padding: 10px 20px;
    color: #002652;
    font-weight: 400;
    font-size: 18px;
    background: #EEE;
    border: 0;
    border-radius: 50px;
}
.form__textarea:focus {
    background: #DDD;
}
.--rtl .form__cc-container {
    direction: ltr;
}
.form__cc-field {
    background: #EEE !important;
    border: 0 !important;
    border-radius: 50px !important;
    padding: 12px 20px !important;
    font-size: 18px !important;
}
.form__cc-field.is-invalid {
    border: 0 !important;
}
.form__cc-input {
    background: transparent !important;
    color: #002652 !important;
    font-weight: 400 !important;
}
.form__select-wrap {
    position: relative;
}
.form__select-wrap::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #002652;
    position: absolute;
    top: 17px;
    right: 20px;
}
.form__select {
    width: 100%;
    display: block;
    padding: 10px 50px 10px 20px;
    color: #002652;
    font-weight: 400;
    font-size: 18px;
    background: #EEE;
    border: 0;
    border-radius: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.form__select:focus {
    background: #DDD;
}
.form__textarea {
    width: 100%;
    display: block;
    padding: 10px 20px;
    color: #002652;
    font-weight: 400;
    font-size: 18px;
    background: #EEE;
    border: 0;
    border-radius: 20px;
}
.form__textarea--no-resize {
    resize: none;
}
.form__textarea:focus {
    background: #DDD;
}
.form__radios {
    padding-top: 10px;
}
.form__radios--inline {
    display: flex;
    justify-content: left;
}
.form__radio {
    margin-bottom: 10px;
}
.form__radios--inline .form__radio {
    margin-bottom: 0;
    margin-right: 20px;
}
.form__radio:last-child {
    margin-bottom: 0;
    margin-right: 0;
}
.form__radio-label {
    color: #002652;
    font-weight: 400;
    font-size: 18px;
    padding-left: 30px;
    display: block;
    position: relative;
    cursor: pointer;
}
.--rtl .form__radio-label {
    padding-left: 0;
    padding-right: 30px;
}
.form__radio-label::before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background: #EEE;
    border-radius: 50%;
    top: 0;
    left: 0;
}
.--rtl .form__radio-label::before {
    left: auto;
    right: 0;
}
.form__radio--checked .form__radio-label::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background: #002652;
    border-radius: 50%;
    top: 5px;
    left: 5px;
}
.--rtl .form__radio--checked .form__radio-label::after {
    left: auto;
    right: 5px;
}
.form__radio-input {
    display: none;
}
.form__field-error {
    display: block;
    margin: 5px 0 0 20px;
    color: #F00;
    font-weight: 400;
    font-size: 14px;
}

.form__checkboxes {
    padding-top: 10px;
}
.form__checkboxes--inline {
    display: flex;
    justify-content: left;
}
.form__checkbox {
    margin-bottom: 10px;
}
.form__checkboxs--inline .form__checkbox {
    margin-bottom: 0;
    margin-right: 20px;
}
.--rtl .form__checkboxs--inline .form__checkbox {
    margin-right: 0;
    margin-left: 20px;
}
.form__checkbox:last-child {
    margin-bottom: 0;
    margin-right: 0;
}
.--rtl .form__checkbox:last-child {
    margin-left: 0;
}
.form__checkbox-label {
    color: #002652;
    font-weight: 400;
    font-size: 18px;
    padding-left: 30px;
    display: block;
    position: relative;
    cursor: pointer;
}
.--rtl .form__checkbox-label {
    padding-left: 0;
    padding-right: 30px;
}
.form__checkbox-label::before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background: #EEE;
    top: 0;
    left: 0;
}
.--rtl .form__checkbox-label::before {
    left: auto;
    right: 0;
}
.form__checkbox--checked .form__checkbox-label::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background: #002652;
    top: 5px;
    left: 5px;
}
.--rtl .form__checkbox--checked .form__checkbox-label::after {
    left: auto;
    right: 5px;
}
.form__checkbox-input {
    display: none;
}

.form__file-wrap {
    width: 100%;
    background: #EEE;
    border-radius: 50px;
    display: flex;
    cursor: pointer;
}
.form__file-wrap::before {
    color: #002652;
    font-weight: 400;
    font-size: 18px;
    padding: 10px 20px;
    content: attr(data-content);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.form__file-wrap::after {
    content: 'Browse';
    margin-left: auto;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background: #002652;
    color: #FFF;
    font-size: 16px;
    padding: 11px 25px;
}
.--rtl .form__file-wrap::after {
    content: 'تصفح';
    margin-left: 0;
    margin-right: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.form__file {
    display: none;
}
.form__buttons {
    text-align: center;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.form__buttons--left {
    text-align: left;
}
.form__button {
    display: inline-block;
    border: 0;
    border-radius: 25px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 15px 40px;
    cursor: pointer;
    margin-right: 20px; /* ? */
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.--rtl .form__button {
    margin-right: 0;
    margin-left: 20px;
}
.form__button:last-child {
    margin-right: 0;
}
.--rtl .form__button:last-child {
    margin-left: 0;
}
.form__button--gold {
    background: #b48c24;
    color: #fff;
}
.form__button--blue {
    background: #002652;
    color: #fff;
}
.form__button--gray {
    background: #EEE;
    color: #002652;
}
.form__button--small {
    font-size: 16px;
    padding: 11px 25px;
}
.form__button--has-loading {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.form__button:disabled {
    background: #DDD;
}
.form__button-loading {
    margin-right: 10px;
}
.--rtl .form__button-loading {
    margin-right: 0;
    margin-left: 10px;
}
.form__link {
    text-decoration: none;
    color: #002652;
    font-weight: 400;
}
.form__link--gold {
    color: #ac7c23;
}
.form__link--underline {
    text-decoration: underline;
}

.password-strength {
    margin: 5px 0 0 20px;
}
.password-strength__rule {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
}
.password-strength__rule:last-child {
    margin: 0;
}
.password-strength__rule-icon {
    color: #ddd;
    margin-right: 5px;
}
.password-strength__rule--success .password-strength__rule-icon {
    color: #b48c24
}
.password-strength__rule-text {
    color: #002652;
}

.progress-bar {
    background: #ddd;
    flex: 1;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}
.progress-bar__bar {
    background: #b48c24;
    padding: 5px 10px;
}
.progress-bar__text {
    text-align: center;
    font-weight: 300;
    color: #fff;
    white-space: nowrap;
    font-size: 14px;
}

.cms-generated {
    margin-bottom: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.cms-generated--no-margin {
    margin: 0;
}
.cms-generated:last-child {
    margin-bottom: 0;
}
.cms-generated h3 {
    margin: 0 0 20px 0;
    font-size: 25px;
    font-weight: 400;
    color: #002652;
}
.cms-generated h4 {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 400;
    color: #002652;
}
.cms-generated p {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    line-height: 150%;
}
.cms-generated p.ql-align-left {
    text-align: left;
}
.cms-generated p.ql-align-center {
    text-align: center;
}
.cms-generated p.ql-align-right {
    text-align: right;
}
.cms-generated p:last-child {
    margin-bottom: 0;
}
.cms-generated strong {
    font-weight: 400;
}
.cms-generated p.ql-align-justify > img {
    display: block;
    width: 100%;
    max-width: 100%;
}
.cms-generated ul {
    list-style-type: none;
    margin: 0 0 20px 0;
    padding: 0;
}
.cms-generated ul:last-child {
    margin-bottom: 0;
}
.cms-generated ul li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
}
.--rtl .cms-generated ul li {
    padding-left: 0;
    padding-right: 30px;
}
.cms-generated ul li:last-child {
    margin-bottom: 0;
}
.cms-generated ul li::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 20px;
    height: 15px;
    background: url('../img/icon-checkmark.png') no-repeat;
}
.--rtl .cms-generated ul li::before {
    left: auto;
    right: 0;
}
.cms-generated ol {
    margin: 0 0 20px 0;
    padding: 0 0 0 30px;
}
.--rtl .cms-generated ol {
    padding: 0 30px 0 0;
}
.cms-generated ol li {
    margin-bottom: 10px;
}
.cms-generated ol li:last-child {
    margin-bottom: 0;
}
.cms-generated .ql-video {
    display: block;
    width: 100%;
    max-width: 100%;
}
.cms-generated p > img {
    display: block;
    max-width: 100%;
}
.cms-generated p.cms-image--center > img {
    margin: auto;
}
.cms-generated p.cms-image--right > img {
    margin-left: auto;
}

.categories-strip {
    margin-bottom: 10px;
}
.categories-strip__list {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.categories-strip__tag {
    margin-right: 10px;
    margin-bottom: 10px;
}
.--rtl .categories-strip__tag {
    margin-right: 0;
    margin-left: 10px;
}
.categories-strip__tag:last-child {
    margin-right: 0;
}
.--rtl .categories-strip__tag:last-child {
    margin-left: 0;
}
.categories-strip__link {
    background: #DDD;
    padding: 5px 10px;
    text-decoration: none;
    color: #888;
    display: block;
    font-size: 16px;
}

.cms-page {
    background: #FFF;
    width: 80%;
    max-width: 100%;
    padding: 50px;
    position: relative;
}
.cms-page__iframe-loader {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    text-align: center;
    display: none;
}
.cms-page--iframe-loading .cms-page__iframe-loader {
    display: block;
}
.cms-page--iframe-loading iframe {
    visibility: hidden;
}
.cms-page iframe {
    border: 0;
}

.service-page {
    background: #FFF;
    width: 80%;
    max-width: 100%;
    padding: 50px;
    display: flex;
}
.service-page__gallery {
    overflow: hidden;
    flex: 1;
    margin-right: 50px;
}
.--rtl .service-page__gallery {
    margin-right: 0;
    margin-left: 50px;
}
.service-page__gallery .swiper-container {
    padding-bottom: 50px;
}
.service-page__gallery-left {
    position: absolute;
    left: 0;
    top: calc(100% - 40px);
    z-index: 1;
    background: #eee;
    padding: 5px 10px;
    cursor: pointer;
    height: 40px;
}
.service-page__gallery-right {
    position: absolute;
    right: 0;
    top: calc(100% - 40px);
    z-index: 1;
    background: #eee;
    padding: 5px 10px;
    cursor: pointer;
    height: 40px;
}
.service-page__gallery-arrow {
    display: block;
    width: auto;
    height: 30px;
}
.service-page__image {
    width: 100%;
    display: block;
}
.service-page__description {
    flex: 1;
}
.service-page__title {
    color: #b48c24;
    font-size: 36px;
    font-weight: 300;
    margin: 0 0 20px 0;
}

.offers-page {
    width: 80%;
    max-width: 100%;
}
.offers-page__listing-row {
    display: flex;
    margin-bottom: 30px;
}
.offers-page__listing-row:last-child {
    margin-bottom: 0;
}
.offers-page__offer {
    background: #FFF;
    margin-right: 30px;
    flex: 1;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.offers-page__offer-appear,
.offers-page__offer-enter {
    opacity: 0;
    position: relative;
    top: 30px;
}
.offers-page__offer-appear.offers-page__offer-appear-active,
.offers-page__offer-enter.offers-page__offer-enter-active {
    opacity: 1;
    top: 0;
}
.offers-page__offer-appear-done,
.offers-page__offer-enter-done {
    transition-delay: 0ms !important;
}
.offers-page__offer:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
}
.offers-page__offer:last-child {
    margin-right: 0;
}
.offers-page__offer-link {
    display: block;
    text-decoration: none;
}
.offers-page__offer-image {
    width: 100%;
    display: block;
    margin-bottom: 20px;
}
.offers-page__offer-partner {
    text-align: center;
    color: #002652;
    /*text-transform: uppercase;*/
    font-size: 24px;
    font-weight: 300;
    margin: 0 20px 20px 20px;
}
.offers-page__offer-title {
    /*text-transform: uppercase;*/
    color: #b48c24;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    margin: 0 20px 20px 20px;
}

.offer-page {
    background: #FFF;
    width: 80%;
    max-width: 100%;
    padding: 50px;
}
.offer-page__main {
    display: flex;
}
.offer-page__gallery {
    flex: 1;
    margin-right: 50px;
    overflow: hidden;
}
.--rtl .offer-page__gallery {
    margin-right: 0;
    margin-left: 50px;
}
.offer-page__gallery .swiper-container {
    padding-bottom: 50px;
}
.offer-page__gallery-left {
    position: absolute;
    left: 0;
    top: calc(100% - 40px);
    z-index: 1;
    background: #eee;
    padding: 5px 10px;
    cursor: pointer;
    height: 40px;
}
.offer-page__gallery-right {
    position: absolute;
    right: 0;
    top: calc(100% - 40px);
    z-index: 1;
    background: #eee;
    padding: 5px 10px;
    cursor: pointer;
    height: 40px;
}
.offer-page__gallery-arrow {
    display: block;
    width: auto;
    height: 30px;
}

.offer-page__image {
    width: 100%;
    display: block;
}
.offer-page__description {
    flex: 1;
}
.offer-page__partner {
    color: #002652;
    font-size: 28px;
    font-weight: 300;
    margin: 0 0 5px 0;
    /*text-transform: capitalize;*/
}
.offer-page__title {
    color: #b48c24;
    font-size: 36px;
    font-weight: 300;
    margin: 0 0 5px 0;
}
.offer-page__subtitle {
    color: #002652;
    font-size: 24px;
    font-weight: 300;
    margin: 0 0 20px 0;
}
.offer-page__description-text {
    margin-top: 20px;
}
.offer-page__expiry {
    margin: 0 0 30px 0;
    font-size: 16px;
    font-weight: 400;
    color: #002652;
    line-height: 150%;
}
.offer-page__expiry:last-child {
    margin-bottom: 0;
}
.offer-page__extended {
    margin-top: 50px;
}
.offer-page__book-now {
    margin-bottom: 20px;
}
.offer-page__map {
    width: 100%;
    height: 600px;
    position: relative;
}
.offer-page__no-locations {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    line-height: 150%;
}
.offer-page__extra {
    background: #eee;
    padding: 20px;
    margin-bottom: 20px;
}
.offer-page__extra-icon {
    margin-right: 10px;
}
.--rtl .offer-page__extra-icon {
    margin-right: 0;
    margin-left: 10px;
}
.offer-page__extra-wrapper {
    margin-bottom: 20px;
}
.offer-page__extra-wrapper:last-child {
    margin-bottom: 0;
}
.offer-page__extra-subtitle {
    font-size: 18px;
    margin-bottom: 5px;
    color: #002652;
    font-weight: 300;
}
.offer-page__validity {
    display: flex;
    flex-direction: row;
}
.offer-page__dow {
    text-align: center;
    margin-right: 10px;
    position: relative;
}
.--rtl .offer-page__dow {
    margin-right: 0;
    margin-left: 10px;
}
.offer-page__dow:last-child {
    margin-right: 0;
    margin-left: 0;
}
.offer-page__dow-circle {
    font-size: 32px;
    color: #DDD;
}
.offer-page__dow--valid .offer-page__dow-circle {
    color: #b48c24;
    opacity: 1;
}
.offer-page__dow-name {
    color: #002652;
    position: absolute;
    font-size: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.offer-page__dow--valid .offer-page__dow-name {
    color: #FFF;
}
.offer-page__cards {
    display: flex;
    flex-direction: row;
}
.offer-page__card {
    flex: 0 1 50px;
    margin-right: 10px;
    max-width: calc((100% - 30px) / 4);
    overflow: hidden;
    border-radius: 5px;
}
.--rtl .offer-page__card {
    margin-right: 0;
    margin-left: 10px;
}
.offer-page__card:last-child {
    margin-right: 0;
    margin-left: 0;
}
.offer-page__card-image {
    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
}
.offer-page__stock {
    font-size: 18px;
    margin-bottom: 5px;
    color: #002652;
    font-weight: 300;
}
.offer-page__stock-unused {
    color: #b48c24;
    font-weight: normal;
}
.offer-page__redeem {
    display: flex;
    flex-direction: row;
}
.offer-page__qr {
    flex: 0;
    padding: 10px;
    background: #fff;
    margin-left: 20px;
}
.--rtl .offer-page__qr {
    margin-left: 0;
    margin-right: 20px;
}
.offer-page__qr svg {
    display: block;   
}
.offer-page__member {
    flex: 1;
}
.offer-page__member-id {
    font-weight: 400;
    font-size: 24px;
    color: #b48c24;
}
.offer-page__share {
    display: flex;
    justify-content: flex-end;
}
.offer-page__share-button {
    margin-right: 10px;
}
.--rtl .offer-page__share-button {
    margin-left: 10px;
}
.offer-page__share-button:last-child {
    margin-right: 0;
    margin-left: 0;
}
.offer-page__share-icon {

}

.vouchers-page {
    width: 80%;
    max-width: 100%;
}
.vouchers-page__listing-row {
    display: flex;
    margin-bottom: 30px;
}
.vouchers-page__listing-row:last-child {
    margin-bottom: 0;
}
.vouchers-page__voucher {
    background: #FFF;
    margin-right: 30px;
    flex: 1;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.vouchers-page__voucher-appear,
.vouchers-page__voucher-enter {
    opacity: 0;
    position: relative;
    top: 30px;
}
.vouchers-page__voucher-appear.vouchers-page__voucher-appear-active,
.vouchers-page__voucher-enter.vouchers-page__voucher-enter-active {
    opacity: 1;
    top: 0;
}
.vouchers-page__voucher-appear-done,
.vouchers-page__voucher-enter-done {
    transition-delay: 0ms !important;
}
.vouchers-page__voucher:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
}
.vouchers-page__voucher:last-child {
    margin-right: 0;
}
.vouchers-page__voucher-link {
    display: block;
    text-decoration: none;
}
.vouchers-page__voucher-image {
    width: 100%;
    display: block;
    margin-bottom: 20px;
}
.vouchers-page__voucher-partner {
    text-align: center;
    color: #002652;
    /*text-transform: uppercase;*/
    font-size: 24px;
    font-weight: 300;
    margin: 0 20px 20px 20px;
}
.vouchers-page__voucher-title {
    /*text-transform: uppercase;*/
    color: #b48c24;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    margin: 0 20px 20px 20px;
}

.voucher-page {
    background: #FFF;
    width: 80%;
    max-width: 100%;
    padding: 50px;
}
.voucher-page__main {
    display: flex;
}
.voucher-page__gallery {
    flex: 1;
    margin-right: 50px;
    overflow: hidden;
}
.--rtl .voucher-page__gallery {
    margin-right: 0;
    margin-left: 50px;
}
.voucher-page__gallery .swiper-container {
    padding-bottom: 50px;
}
.voucher-page__gallery-left {
    position: absolute;
    left: 0;
    top: calc(100% - 40px);
    z-index: 1;
    background: #eee;
    padding: 5px 10px;
    cursor: pointer;
    height: 40px;
}
.voucher-page__gallery-right {
    position: absolute;
    right: 0;
    top: calc(100% - 40px);
    z-index: 1;
    background: #eee;
    padding: 5px 10px;
    cursor: pointer;
    height: 40px;
}
.voucher-page__gallery-arrow {
    display: block;
    width: auto;
    height: 30px;
}
.voucher-page__image {
    width: 100%;
    display: block;
}
.voucher-page__description {
    flex: 1;
}
.voucher-page__partner {
    color: #002652;
    font-size: 28px;
    font-weight: 300;
    margin: 0 0 5px 0;
    /*text-transform: uppercase;*/
}
.voucher-page__title {
    color: #b48c24;
    font-size: 36px;
    font-weight: 300;
    margin: 0 0 5px 0;
}
.voucher-page__title--margin-bottom {
    margin-bottom: 20px;
}
.voucher-page__subtitle {
    color: #002652;
    font-size: 24px;
    font-weight: 300;
    margin: 0 0 20px 0;
}
.voucher-page__save {
    color: #b48c24;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}
.voucher-page__description-text {
    margin-top: 20px;
}
.voucher-page__expiry {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 400;
    color: #002652;
    line-height: 150%;
}
.voucher-page__expiry:last-child {
    margin-bottom: 0;
}
.voucher-page__product {
    margin: 0 0 30px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333333;
    padding: 10px;
    background: #DDD;
    /*text-transform: uppercase;*/
}
.voucher-page__pricing {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.voucher-page__pricing-price {
    margin-right: auto;
    font-size: 40px;
    font-weight: 300;
    color: #b48c24
}
.--rtl .voucher-page__pricing-price {
    margin-right: 0;
    margin-left: auto;
}
.voucher-page__qty {
    background: #EEE;
    padding: 15px;
    border-radius: 25px;
    font-size: 20px;
    border: 0;
    margin-right: 10px;
    width: auto;
    text-align: center;
}
.--rtl .voucher-page__qty {
    margin-right: 0;
    margin-left: 10px;
}
.voucher-page__qty:focus {
    background: #DDD;
}
.voucher-page__qty-select-wrap {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}
.--rtl .voucher-page__qty-select-wrap {
    margin-right: 0;
    margin-left: 10px;
}
.voucher-page__qty-select-wrap::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #002652;
    position: absolute;
    top: 25px;
    right: 15px;
}
.voucher-page__qty-select {
    background: #EEE;
    padding: 15px 40px 15px 15px;
    border-radius: 25px;
    font-size: 20px;
    border: 0;
    width: auto;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.voucher-page__qty-select:focus {
    background: #DDD;
}
.voucher-page__terms-agree {
    margin-top: 15px;
}
.voucher-page__extended {
    margin-top: 50px;
}
.voucher-page__map {
    width: 100%;
    height: 600px;
    position: relative;
}
.voucher-page__no-locations {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    line-height: 150%;
}
.voucher-page__in-cart {
    text-align: right;
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 400;
    color: #002652;
    line-height: 150%;
}
.--rtl .voucher-page__in-cart {
    text-align: left;
}
.voucher-page__voucher-list {
    margin-bottom: 20px;
    padding: 10px;
    background: #EEE;
}
.voucher-page__voucher-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.voucher-page__voucher-item:last-child {
    margin-bottom: 0;
}
.voucher-page__voucher-item-image-wrapper {
    width: 100px;
    margin-right: 20px;
}
.voucher-page__voucher-item-image {
    width: 100%;
    display: block;
    height: auto;
}
.voucher-page__voucher-item-info {
    flex: 1;
    margin-right: 20px;
}
.voucher-page__voucher-item-partner {
    color: #b48c24;
    margin-bottom: 5px;
    font-size: 16px;
}
.voucher-page__voucher-item-title {
    color: #002752;
    font-size: 20px;
    margin-bottom: 5px;
}
.voucher-page__voucher-item-qty {
    font-size: 24px;
    text-align: right;
}
.voucher-page__voucher-item-out-of-stock {
    text-align: right;
    color: #f00;
    margin: 5px 0 0 0;
    text-transform: uppercase;
    font-size: 14px;
}
.voucher-page__package-limit {
    margin-bottom: 20px;
}

.filter-multiselect {
    background: transparent;
    cursor: pointer;
    position: relative;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.filter-multiselect--open {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
}
.filter-multiselect__selected {
    padding: 0 50px 10px 20px;
    color: #002652;
    font-weight: 400;
    font-size: 18px;
    background: #EEE;
    border-radius: 25px;
    display: flex;
    flex-wrap: wrap;
}
.filter-multiselect--open .filter-multiselect__selected {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.filter-multiselect__selected::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #002652;
    position: absolute;
    top: 22px;
    right: 20px;
}
.filter-multiselect__placeholder {
    margin: 15px 0 5px;
}
.filter-multiselect__selected-item {
    background: #FFF;
    padding: 5px 10px;
    border-radius: 15px;
    margin-right: 10px;
    margin-top: 10px
}
.--rtl .filter-multiselect__selected-item {
    margin-right: 0;
    margin-left: 10px;
}
.filter-multiselect__selected-item--single {
    width: 100%;
}
.filter-multiselect__selected-item:last-child {
    margin-right: 0;
}
.--rtl .filter-multiselect__selected-item:last-child {
    margin-left: 0;
}
.filter-multiselect__options {
    display: none;
    z-index: 101;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px 0;
    background: #FFF;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border-left: 1px solid #EEE;
    border-right: 1px solid #EEE;
    border-bottom: 1px solid #EEE;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 211px;
}
.--rtl .filter-multiselect__options {
    left: auto;
    right: 0;
}
.filter-multiselect--open .filter-multiselect__options {
    display: block;
}
.filter-multiselect__option {
    padding: 10px 20px;
    display: flex;
    color: #333;
}
.filter-multiselect__option:hover {
    background: #EEE;
}
.filter-multiselect__option--heading {
    background: #aaa;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #FFF;
}
.filter-multiselect__option--heading:hover {
    background: #aaa;
}
.filter-miltiselect__icon {
    margin-right: 10px;
}
.--rtl .filter-miltiselect__icon {
    margin-right: 0;
    margin-left: 10px;
}
.filter-multiselect__option--heading .filter-miltiselect__icon {
    display: none;
}

.search-page {
    width: 80%;
    max-width: 100%;
}
.search-page__filters {
    background: #FFF;
    padding: 50px;
    margin-bottom: 30px;
    display: flex;
}
.search-page__filter {
    margin-right: 30px;
    width: calc((100% - 30px) / 3);
}
.--rtl .search-page__filter {
    margin-right: 0;
    margin-left: 30px;
}
.search-page__filter:last-child {
    margin-right: 0;
}
.--rtl .search-page__filter:last-child {
    margin-left: 0;
}
.search-page__listing-row {
    display: flex;
    margin-bottom: 30px;
}
.search-page__listing-row:last-child {
    margin-bottom: 0;
}
.search-page__result {
    background: #FFF;
    margin-right: 30px;
    flex: 1;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    display: flex;
    flex-direction: column;
    max-width: calc((100% - 90px) / 4);
}
.--rtl .search-page__result {
    margin-right: 0;
    margin-left: 30px;
}
.search-page__result-appear,
.search-page__result-enter {
    opacity: 0;
    position: relative;
    top: 30px;
}
.search-page__result-appear.search-page__result-appear-active,
.search-page__result-enter.search-page__result-enter-active {
    opacity: 1;
    top: 0;
}
.search-page__result-appear-done,
.search-page__result-enter-done {
    transition-delay: 0ms !important;
}
.search-page__result:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
}
.search-page__result:last-child {
    margin-right: 0;
}
.--rtl .search-page__result:last-child {
    margin-left: 0;
}
.search-page__result-link {
    display: block;
    text-decoration: none;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}
.search-page__result-image-wrapper {
    position: relative;
    margin-bottom: 20px;
}
.search-page__result-image {
    width: 100%;
    display: block;
}
.search-page__result-type {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #FFF;
    padding: 5px 10px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    color: #666;
}
.--rtl .search-page__result-type {
    right: auto;
    left: 15px;
}
.search-page__result-subtype {
    position: absolute;
    bottom: 15px;
    left: 15px;
    padding: 5px 10px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    color: #fff;
}
.--rtl .search-page__result-subtype {
    left: auto;
    right: 15px;
}
.search-page__result-subtype--sold {
    background: red;
}
.search-page__result-subtype--reserved {
    background: orange;
}
.search-page__result-partner, .search-page__result-main-title {
    text-align: center;
    color: #002652;
    /*text-transform: uppercase;*/
    font-size: 24px;
    font-weight: 300;
    margin: 0 20px 20px 20px;
}
.search-page__result-title, .search-page__result-price {
    text-transform: uppercase;
    color: #b48c24;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    margin: 0 20px 20px 20px;
}

.search-page__message {
    background: #FFF;
    padding: 50px;
}
.search-page__message-text {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    line-height: 150%;
}
.search-page__loading {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.estore-page {
    width: 80%;
    max-width: 100%;
}
.estore-page__banner {
    display: block;
    max-width: 100%;
    margin-bottom: 20px;
}
.estore-page__title {
    color: #002752;
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    margin: 0 0 20px 0;
}
.estore-page__header {
    margin-bottom: 20px;
}
.estore-page__header p {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    line-height: 150%;
    text-align: center;
}
.estore-page__listing-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
}
.estore-page__listing-title {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    color: #002652;
    padding-left: 10px;
    border-left: 3px solid #b48c24;
    flex: 1;
    margin-right: 30px;
}
.--rtl .estore-page__listing-title {
    padding-left: 0;
    padding-right: 10px;
    border-left: 0;
    border-right: 3px solid #b48c24;
    margin-right: 0;
    margin-left: 30px;
}
.estore-page__listing-controls {
    display: flex;
}
.estore-page__listing-control-dropdown {
    margin-left: 20px;
}
.--rtl .estore-page__listing-control-dropdown {
    margin-left: 0;
    margin-right: 20px;
}
.estore-page__listing-control-dropdown:first-child {
    margin-left: 0;
}
.--rtl .estore-page__listing-control-dropdown:first-child {
    margin-right: 0;
}
.estore-page__listing-control-type {
    border: 0;
    padding: 0;
    background: transparent;
    color: #ddd;
    margin-left: 20px;
    cursor: pointer;
    font-size: 24px;
}
.--rtl .estore-page__listing-control-type {
    margin-left: 0;
    margin-right: 20px;
}
.estore-page__listing-control-type--active {
    color: #b48c24;
}
.estore-page__listing--grid .estore-page__listing-content {
    display: flex;
    flex-wrap: wrap;
}
.estore-page__filters {
    padding: 0;
    margin-bottom: 0;
    margin-top: 20px;
}
.estore-page__product {
    margin-top: 30px;
    transition-property: opacity, top;
    transition-duration: 03.s;
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.estore-page__product-appear,
.estore-page__product-enter {
    opacity: 0;
    position: relative;
    top: 30px;
}
.estore-page__product-appear.estore-page__product-appear-active,
.estore-page__product-enter.estore-page__product-enter-active {
    opacity: 1;
    top: 0;
}
.estore-page__product-appear-done,
.estore-page__product-enter-done {
    transition-delay: 0ms !important;
}
.estore-page__product-link {
    display: block;
    text-decoration: none;
}
.estore-page__listing--grid .estore-page__product {
    border: 1px solid #ddd;
    flex: 1 1;
    max-width: calc((100% - 90px) / 4);
    margin-right: 30px;
}
.--rtl .estore-page__listing--grid .estore-page__product {
    margin-right: 0;
    margin-left: 30px;
}
.estore-page__listing--list .estore-page__product {
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
}
.estore-page__listing--list .estore-page__product:last-child {
    padding-bottom: 0;
    border: 0;
}
.estore-page__listing--list .estore-page__product-link {
    display: flex;
    align-items: flex-start;
}
.estore-page__listing--grid .estore-page__product:nth-child(4n) {
    margin-right: 0;
}
.--rtl .estore-page__listing--grid .estore-page__product:nth-child(4n) {
    margin-left: 0;
}
.estore-page__listing--list .estore-page__product-images {
    width: calc((100% - 30px) / 3);
    margin-right: 30px;
}
.--rtl .estore-page__listing--list .estore-page__product-images {
    margin-right: 0;
    margin-left: 30px;
}
.estore-page__listing--list .estore-page__product-image-wrapper {
    position: relative;
    border: 1px solid #ddd;
}
.estore-page__product-image {
    display: block;
    height: auto;
    width: 100%;
}
.estore-page__product-image-large {
    z-index: 1;
    direction: ltr;
}
.estore-page__listing--grid .estore-page__product-info {
    padding: 20px;
}
.estore-page__listing--list .estore-page__product-info {
    flex: 1;
}
.estore-page__product-title {
    margin: 0 0 20px 0;
    display: flex;
    align-items: flex-start;
}
.estore-page__product-title-text {
    flex: 1;
    margin-right: 10px;
    font-size: 22px;
    font-weight: 400;
    color: #002652;
    /*text-transform: uppercase;*/
    height: 50px;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.--rtl .estore-page__product-title-text {
    margin-right: 0;
    margin-left: 10px;
}
.estore-page__product-favorite {
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    font-size: 22px;
}
.estore-page__product-favorite-icon {
    color: #ddd;
}
.estore-page__product-favorite-icon:hover {
    color: #b48c24; 
}
.estore-page__product-price {
    margin-bottom: 10px;
}
.estore-page__product-price-current {
    font-weight: 500;
    color: #002652;
}
.estore-page__listing--grid .estore-page__product-price-current {
    font-size: 26px;
}
.estore-page__listing--list .estore-page__product-price-current {
    font-size: 32px;
}
.estore-page__product-price-old {
    font-weight: 400;
    color: #ddd;
    text-decoration: line-through;
}
.estore-page__listing--grid .estore-page__product-price-old {
    font-size: 20px;
}
.estore-page__listing--list .estore-page__product-price-old {
    font-size: 24px;
}
.estore-page__product-description {
    padding: 15px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}
.estore-page__product-description-text > p {
    margin: 0 0 10px 0;
    font-size: 14px;
    font-weight: 400;
    color: #002652;
    line-height: 150%;
}
.estore-page__product-rating-stars {
    margin-bottom: 5px;
}
.estore-page__product-rating-star {
    color: #ddd;
}
.estore-page__product-rating-star--active {
    color: #b48c24;
}
.estore-page__product-review-count {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #002652;
}
.estore-page__product-features {
    margin-bottom: 30px;
}
.estore-page__product-feature {
    font-size: 14px;
    font-weight: 400;
    color: #002652;
}
.estore-page__product-feature-icon {
    margin-right: 10px;
    color: #b48c24;
}
.--rtl .estore-page__product-feature-icon {
    margin-right: 0;
    margin-left: 10px;
}
.estore-page__product-tags {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}
.estore-page__product-tag {
    border: 1px solid;
    border-radius: 50px;
    font-weight: 300;
    background: #fff;
}
.estore-page__product-tag--image {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 5px 10px;
    font-size: 18px;
}
.estore-page__product-tag--info {
    padding: 5px 10px;
    font-size: 14px;
}
.estore-page__product-tag--discount {
    border-color: #f00;
    color: #f00;
}
.estore-page__message {
    margin-top: 30px;
}
.estore-page__message-text {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    line-height: 150%;
}
.estore-page__message-text--center {
    text-align: center;
}
.estore-page__loading {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.estore-page__product-view {
    margin-bottom: 50px;
}
.estore-page__gallery {
    margin-top: 20px;
    padding: 0 50px;
    position: relative;
}
.estore-page__image {
    display: block;
    width: 100%;
    cursor: pointer;
}
.estore-page__gallery-left {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.estore-page__gallery-right {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}
.estore-page__gallery-arrow {
    display: block;
    width: 30px;
    cursor: pointer;
}
.estore-page__product-buttons {
    display: flex;
}
.estore-page__qty-select-wrap {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}
.--rtl .estore-page__qty-select-wrap {
    margin-right: 0;
    margin-left: 10px;
}
.estore-page__qty-select-wrap::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #002652;
    position: absolute;
    top: 16px;
    right: 15px;
    pointer-events: none;
}
.estore-page__qty-select {
    background: #EEE;
    padding: 10px 40px 10px 15px;
    border-radius: 25px;
    font-size: 16px;
    border: 0;
    width: auto;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.estore-page__qty-select:focus {
    background: #DDD;
}
.estore-page__product-button {
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
}
.estore-page__listing--list .estore-page__product-button {
    flex: 0;
    overflow: initial;
}
.estore-page__in-cart {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #002652;
    margin: 10px 0 0 0;
}
.--rtl .estore-page__in-cart {
    text-align: right;
}

/* Prepaid card */

.prepaid-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.prepaid-card__main {
    padding: 50px;
    background: #FFF;
    max-width: 100%;
    width: 80%;
}
.prepaid-card__card {
    width: 485px;
    max-width: 100%;
    position: relative;
    margin-bottom: 30px;
}
.prepaid-card__card-loading {
    width: 485px;
    max-width: 100%;
    position: relative;
    margin-bottom: 30px;
}
.prepaid-card__card-loading::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((62 / 97) * 100%);
}
.prepaid-card__card-loading-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee;
    border-radius: 15px;
}
.prepaid-card__card-image {
    display: block;
    width: 100%;
}
.prepaid-card__card-text {
    font-family: "OCRAExt";
    color: #fff;
    position: absolute;
}
.prepaid-card__card-text--center {
    text-align: center;
}
.prepaid-card__status {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    width: 485px;
    max-width: 100%;
    padding: 10px;
    background: #eee;
    justify-content: space-between;
}
.prepaid-card__status-text {
    margin-right: 20px;
    margin-bottom: 0;
}
.--rtl .prepaid-card__status-text {
    margin-right: 0;
    margin-left: 20px;
}
.prepaid-card__status-text-label {
    margin-right: 20px;
    display: inline-block;
}
.--rtl .prepaid-card__status-text-label {
    margin-right: 0;
    margin-left: 20px;
}
.prepaid-card__status-text-value {
    font-weight: 400;
}
.prepaid-card__status-button {

}
.prepaid-card__balance {

}
.prepaid-card__wallet {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    align-items: center;
}
.prepaid-card__currency {
    margin-right: 20px;
}
.--rtl .prepaid-card__currency {
    margin-right: 0;
    margin-left: 20px;
}
.prepaid-card__currency:last-child {
    margin-bottom: 0;
}
.prepaid-card__currency-code {
    font-weight: 400;
    display: block;
    font-size: 24px;
    margin-bottom: 5px;
    color: #b48c24;
}
.prepaid-card__currency-name {
    display: block;
    color: #666;
}
.prepaid-card__wallet-amount {
    font-weight: 500;
    font-size: 32px;
    color: #002652;
}
.prepaid-card__gate {
    padding: 50px;
    background: #FFF;
    max-width: 100%;
}
.prepaid-card__gate-form {
    max-width: 100%;
    width: 600px;
}
.prepaid-card__transfer {
    padding: 50px;
    background: #FFF;
    max-width: 100%;
}
.prepaid-card__transfer-form {
    max-width: 100%;
    width: 600px;
}
.prepaid-card__transfer-form-loading {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}
.prepaid-card__filters {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
.prepaid-card__filter {
    margin-right: 30px;
    max-width: calc((100% - 60px) / 3);
}
.--rtl .prepaid-card__filter {
    margin-right: 0;
    margin-left: 30px;
}
.prepaid-card__filter:last-child {
    margin-right: 0;
    margin-left: 0;
}
.prepaid-card__transactions {
    border: 1px solid #ddd;
}
.prepaid-card__tx-header {
    background: #eee;
    padding: 10px
}
.prepaid-card__tx {
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.prepaid-card__tx:last-child {
    border-bottom: 0;
}
.prepaid-card__tx:hover {
    background: #f7f7f7;
}
.prepaid-card__tx-meta {
    margin-right: 30px;
}
.--rtl .prepaid-card__tx-meta {
    margin-right: 0;
    margin-left: 30px;
}
.prepaid-card__tx-location {
    font-size: 24px;
    font-weight: 500;
    color: #002652;
    margin-bottom: 5px;
}
.prepaid-card__tx-description {
    font-weight: 300;;
    color: #666;
}
.prepaid-card__tx-amount {
    text-align: right;
}
.--rtl .prepaid-card__tx-amount {
    text-align: left;
}
.prepaid-card__tx-amount-transaction {
    color: #b48c24;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
}
.prepaid-card__tx-amount-billing {
    color: #666;
    font-weight: 300;
}

.dropdown {
    position: relative;
    cursor: pointer;
}
.dropdown__selected {
    display: flex;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 18px;
    align-items: center;
}
.dropdown--open .dropdown__selected {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.dropdown__text {
    font-size: 14px;
    font-weight: 400;
    color: #002652;
    flex: 1;
    margin-right: 10px;
}
.--rtl .dropdown__text {
    margin-right: 0;
    margin-left: 10px;
}
.dropdown__icon {
    font-size: 12px;
}
.dropdown__options {
    display: none;
    z-index: 101;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px 0;
    background: #FFF;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
}
.dropdown__option {
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #002652;
}
.dropdown__option:hover {
    background: #ddd;
}
.dropdown--open .dropdown__options {
    display: block;
}

.react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #DDD;
}
.react-tabs__tab {
    padding: 15px 20px;
    border-left: 1px solid #DDD;
    border-top: 1px solid #DDD;
    border-right: 1px solid #DDD;
    border-bottom: 1px solid #FFF;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    position: relative;
    z-index: 5;
    top: 1px;
    background: #DDD;
    text-transform: uppercase;
}
.react-tabs__tab--selected {
    background: #FFF;
    color: #b48c24;
}
.react-tabs__tab-panel {
    background: #FFF;
    display: none;
    padding: 20px;
    border-left: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
    border-right: 1px solid #DDD;
}
.react-tabs__tab-panel--selected {
    display: block;
}

.all-categories {
    width: 80%;
    max-width: 100%;
}
.all-categories__row {
    display: flex;
    margin-bottom: 30px;
}
.all-categories__row:last-child {
    margin-bottom: 0;
}
.all-categories__category {
    background: #FFF;
    margin-right: 30px;
    flex: 1;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    max-width: calc((100% - 90px) / 4);
}
.--rtl .all-categories__category {
    margin-right: 0;
    margin-left: 30px;
}
.all-categories__category-appear,
.all-categories__category-enter {
    opacity: 0;
    position: relative;
    top: 30px;
}
.all-categories__category-appear.all-categories__category-appear-active,
.all-categories__category-enter.all-categories__category-enter-active {
    opacity: 1;
    top: 0;
}
.all-categories__category-appear-done,
.all-categories__category-enter-done {
    transition-delay: 0ms !important;
}
.all-categories__category:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
}
.all-categories__category:last-child {
    margin-right: 0;
}
.--rtl .all-categories__category:last-child {
    margin-left: 0;
}
.all-categories__link {
    display: flex;
    padding: 20px;
    text-decoration: none;
}
.all-categories__image-wrapper {
    margin-right: 20px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.--rtl .all-categories__image-wrapper {
    margin-right: 0;
    margin-left: 20px;
}
.all-categories__image {
    width: 80px;
    height: 80px;
}
.all-categories__title {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 10px 0;
    color: #b48c24;
}
.all-categories__description {
    margin: 0;
    font-weight: 400;
    color: #333;
    font-size: 14px;
}

.payment-request {
    background: #FFF;
    width: 1000px;
    max-width: 100%;
    padding: 50px;
}
.payment-request__details {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
}
.payment-request__name {
    flex: 1 1;
    overflow: hidden;
}
.payment-request__amount {
    flex: 1 1;
    text-align: right;
    overflow: hidden;
}
.--rtl .payment-request__amount {
    text-align: left;
}
.payment-request__label {
    display: block;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 300;
    color: #b48c24;
}
.payment-request__value {
    font-size: 20px;
}

.cart {
    background: #FFF;
    width: 1000px;
    max-width: 100%;
    padding: 50px;
}
.cart__header {
    display: flex;
    color: #002652;
    font-weight: 400;
    font-size: 18px;
    justify-content: stretch;
    border-bottom: 1px solid #DDD;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.cart__header-product {
    flex: 1;
    margin-right: 20px;
}
.--rtl .cart__header-product {
    margin-right: 0;
    margin-left: 20px;
}
.cart__header-price {
    margin-right: 20px;
    width: 100px;
    max-width: 100px;
    text-align: right;
}
.--rtl .cart__header-price {
    margin-right: 0;
    margin-left: 20px;
    text-align: left;
}
.cart__header-qty {
    margin-right: 20px;
    width: 60px;
    max-width: 60px;
}
.--rtl .cart__header-qty {
    margin-right: 0;
    margin-left: 20px;
}
.cart__header-subtotal {
    text-align: right;
    padding-right: 50px;
    width: 150px;
}
.--rtl .cart__header-subtotal {
    text-align: left;
    padding-right: 0;
    padding-left: 50px;
}
.cart__row {
    display: flex;
    justify-content: stretch;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #EEE;
}
.cart__row:last-child {
    margin-bottom: 0;
    border: 0;
    padding: 0;
}
.cart__image-wrapper {
    width: 100px;
    margin-right: 20px;
}
.--rtl .cart__image-wrapper {
    margin-right: 0;
    margin-left: 20px;
}
.cart__image {
    display: block;
    width: 100%;
    height: auto;
}
.cart__description {
    flex: 1;
    color: #b48c24;
    font-weight: 400;
    padding-top: 6px;
    margin-right: 20px;
}
.--rtl .cart__description {
    margin-right: 0;
    margin-left: 20px;
}
.cart__price {
    margin-right: 20px;
    font-weight: 300;
    padding-top: 6px;
    color: #333;
    width: 100px;
    max-width: 100px;
    text-align: right;
}
.--rtl .cart__price {
    margin-right: 0;
    margin-left: 20px;
    text-align: left;
}
.cart__qty {
    width: 60px;
    max-width: 60px;
    margin-right: 20px;
}
.--rtl .cart__qty {
    margin-right: 0;
    margin-left: 20px;
}
.cart__qty-input {
    display: block;
    width: 100%;
    padding: 5px;
    border: 1px solid #DDD;
    font-weight: 300;
    color: #002652;
}
.cart__subtotal {
    padding-top: 6px;
    width: 100px;
    text-align: right;
    color: #333;
}
.--rtl .cart__subtotal {
    text-align: left;
}
.cart__remove {
    width: 50px;
    text-align: center;
    padding-top: 6px;
}
.cart__remove-icon {
    color: #F00;
    cursor: pointer;
}
.cart__total {
    margin: 10px 0 30px 0;
    padding: 10px 0;
    background: #EEE;
    border-top: 1px solid #DDD;
}
.cart__total-shipping {
    font-size: 16px;
    font-weight: 400;
    color: #002652;
    text-align: right;
    margin: 0 50px 0 0;
}
.--rtl .cart__total-shipping {
    text-align: left;
    margin: 0 0 0 50px;
}
.cart__total-fee {
    font-size: 16px;
    font-weight: 400;
    color: #002652;
    text-align: right;
    margin: 0 50px 10px 0;
}
.--rtl .cart__total-fee {
    text-align: left;
    margin: 0 0 0 50px;
}
.cart__total-amount {
    font-size: 24px;
    font-weight: 400;
    color: #002652;
    text-align: right;
    margin: 0 50px 0 0;
}
.--rtl .cart__total-amount {
    text-align: left;
    margin: 0 0 0 50px;
}
.cart__checkout {
    margin: 0 0 30px 0;
}
.cart__controls {
    display: flex;
    justify-content: space-between;
}
.cart__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cart__empty-icon {
    color: #EEE;
    margin-bottom: 30px;
}
.cart__empty-text {
    margin: 0;
    font-size: 20px;
    font-weight: 300;
    color: #333;
}
.cart__empty-link {
    text-decoration: none;
    color: #002652;
    font-weight: 400;
}
.cart__sub-expired {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #EEE;
    margin-bottom: 30px;
    text-align: center;
}
.cart__sub-expired-icon {
    color: #002652;
    margin-bottom: 30px;
}
.cart__sub-expired-text {
    margin: 0 0 20px 0;
    font-size: 20px;
    font-weight: 300;
    color: #333;
}
.cart__sub-expired-text:last-child {
    margin-bottom: 0;
}
.cart__sub-expired-link {
    text-decoration: none;
    color: #002652;
    font-weight: 400;
}

.insurance {
    background: #FFF;
    max-width: 100%;
    width: 80%;
    padding: 0 50px 50px;
}
.insurance__image {
    display: block;
    max-width: 100%;
    width: 200px;
    margin-bottom: 10px;
}
.insurance__form {
    padding: 20px;
    background: #f7f7f7;
}

.lease-to-own {
    background: #FFF;
    max-width: 100%;
    width: 80%;
    padding: 0 50px 50px;
}
.lease-to-own__image {
    display: block;
    max-width: 100%;
    width: 200px;
    margin-bottom: 10px;
}
.lease-to-own__pdf {
    margin-bottom: 10px;
}
.lease-to-own__pdf-icon {
    margin-right: 10px;
    font-size: 18px;
}
.lease-to-own__pdf-name {
    font-size: 16px;
    font-weight: 300;
    color: #333;
}
.lease-to-own__form {
    padding: 20px;
    background: #f7f7f7;
}

.temp-store {
    background: #FFF;
    max-width: 100%;
    width: 80%;
    padding: 0 50px 50px;
}

.footer__content {
    background: #002752;
    padding: 30px 0;
}
.footer__content-inner {
    width: 1500px;
    margin: auto;
    max-width: 100%;
    display: flex;
}
.footer__more-links {
    flex: 0.8;
    margin-right: 50px;
}
.--rtl .footer__more-links {
    margin-right: 0;
    margin-left: 50px;
}
.footer__location {
    flex: 1.2;
    margin-right: 50px;
}
.--rtl .footer__location {
    margin-right: 0;
    margin-left: 50px;
}
.footer__sns-apps {
    flex: 0.8;
    margin-right: 50px;
}
.--rtl .footer__sns-apps {
    margin-right: 0;
    margin-left: 50px;
}
.footer__sns {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
}
.footer__sns-item {
    margin-right: 15px;
}
.--rtl .footer__sns-item {
    margin-right: 0;
    margin-left: 15px;
}
.footer__sns-item:last-child {
    margin-right: 0;
}
.--rtl .footer__sns-item:last-child {
    margin-left: 0;
}
.footer__sns-link {
    text-decoration: none;
}
.footer__sns-image {
    display: block;
    opacity: 0.8;
}
.footer__sns-name {
    font-weight: 300;
    font-size: 24px;
    color: #fff;
    opacity: 0.8;
    margin: 15px 0 0 0;
}
.--rtl .footer__sns-name {
    text-align: right;
}
.footer__apps {
    border-top: 1px solid #335275;
    margin-top: 25px;
    padding-top: 25px;
}
.footer__apps-title {
    font-weight: 500;
    color: #fff;
    opacity: 0.8;
    font-size: 18px;
    margin: 0 0 10px 0;
}
.footer__apps-stores {
    display: flex;
    flex-direction: column;
}
.footer__apps-store-link {
    margin-bottom: 10px;
}
.footer__apps-store-link:last-child {
    margin-bottom: 0;
}
.footer__apps-store-image {
    display: block;
    max-width: 100%;
    opacity: 0.8;
}
.footer__iso {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #335275;
    margin-top: 25px;
    padding-top: 25px;
}
.footer__iso-image {
    display: block;
    max-width: 100%;
    width: 200px;
    height: auto;
}
.footer__ssf {
    flex: 1.2;
    align-self: center;
}
.footer__ssf-link {
    text-decoration: none;
}
.footer__ssf-image {
    display: block;
    max-width: 100%;
    opacity: 0.8;
}
.footer__float {
    position: fixed;
    right: 30px;
    width: 50px;
    height: 50px;
    background: #b48c24;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0;
    padding: 0;
    overflow: hidden;
    z-index: 1000;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in;
    opacity: 1;
}
.footer__float--hidden {
    pointer-events: none;
    opacity: 0;
}
.footer__float--arrow-top {
    /*bottom: calc(170px - 80px);*/
    bottom: calc(250px - 80px);
    right: 23px;
}
.footer__float--chat {
    bottom: 30px;
}
.footer__float--report {
    bottom: calc(100px - 80px);
}
.footer__arrow-top {
    border: solid #FFF;
    border-width: 0 3px 3px 0;
    display: block;
    padding: 10px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    position: relative;
    top: 5px;
}
.footer__chat {
    color: #fff;
    font-size: 24px;
}
.footer__report {
    color: #fff;
    font-size: 24px;
}

.motto {
    width: 1300px;
    max-width: 100%;
    margin: auto;
    padding: 40px 0;
}
.motto__title {
    text-align: center;
    margin: 0 auto 30px auto;
    position: relative;
    max-width: 60%;
}
.motto__title::before {
    content: '';
    display: block;
    height: 1px;
    left: 0;
    right: 0;
    top: 50%;
    background: #002752;
    position: absolute;
    z-index: -1;
}
.motto__title-text {
    color: #002752;
    font-size: 35px;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
    background: #fff;
    padding: 0 30px;
}
.motto__text {
    text-align: center;
    font-size: 22px;
    font-weight: 300;
    font-style: italic;
    color: #000;
    line-height: 150%;
    margin: 0;
}

.footer-section__title {
    position: relative;
    margin: 0 0 30px 0;
    display: flex;
    align-items: center;
}
.footer-section__title::after {
    content: '';
    display: block;
    opacity: 0.8;
    height: 1px;
    background: #fff;
    margin-left: 25px;
    flex: 0 1 140px;
}
.--rtl .footer-section__title::after {
    margin-left: 0;
    margin-right: 25px;
}
.footer-section__title-text {
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    background: #002752;
    white-space: nowrap;
}
.footer-section__menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.footer-section__menu-item {
    margin-bottom: 10px;
}
.footer-section__menu-item:last-child {
    margin-bottom: 0;
}
.footer-section__menu-link {
    text-decoration: none;
    font-size: 16px;
    opacity: 0.8;
    color: #fff;
    font-weight: 300;
}
.footer-section__text {
    font-size: 16px;
    font-weight: 100;
    color: #fff;
    opacity: 0.8;
    font-style: normal;
    line-height: 150%;
}
.footer-section__text p {
    margin: 0 0 20px 0;
}
.footer-section__text p:last-child {
    margin-bottom: 0;
}
.footer-section__text strong {
    font-weight: 500;
}

.copyright {
    background: #001b39;
    padding: 30px 0;
    display: flex;
    justify-content: center;
}
.copyright__text {
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    color: #fff;
}
.copyright__links {
    border-left: 1px solid #fff;
    margin-left: 20px;
    padding-left: 20px;
}
.--rtl .copyright__links {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
    border-right: 1px solid #fff;
    margin-right: 20px;
    padding-right: 20px;
}
.copyright__link {
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    margin-right: 20px;
}
.--rtl .copyright__link {
    margin-right: 0;
    margin-left: 20px;
}
.copyright__link:last-child {
    margin-right: 0;
    margin-left: 0;
}

.checkout-tooltip {
    background: #FFF;
    padding: 20px;
    position: absolute;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    z-index: 100;
    max-width: calc(100vw - 40px);
}
.checkout-tooltip__arrow {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFF;
    position: absolute;
    top: -10px;
}
.checkout-tooltip__text {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    line-height: 150%;
}
.checkout-tooltip__buttons {
    display: flex;
    justify-content: space-between;
}
.checkout-tooltip__buttons button:first-child {
    margin-right: 30px;
}
.--rtl .checkout-tooltip__buttons button:first-child {
    margin-right: 0;
    margin-left: 30px;
}

.error-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    background: lightcoral;
    padding: 10px;
    color: #fff;
    align-items: center;
    font-size: 16px;
}
.error-box__icon {
    margin-right: 10px;
}
.error-box__text {
    margin: 0;
    font-weight: 300;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal__content {
    background: #FFF;
    padding: 20px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 40px);
}
.modal__content--favorite-categories {
    display: flex;
    flex-direction: column;
    width: 500px;
}
.modal__content--search-result {
    padding: 0;
    width: 400px;
    max-width: 100%;
    max-height: 100%;
    position: relative;
}
.modal__content--message {
    
}
.modal__title {
    margin: 0 0 20px 0;
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
    color: #002652;
}
.modal__title--center {
    text-align: center;
}
.modal__text {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    line-height: 150%;
}
.modal__text--center {
    text-align: center;
}
.modal__buttons {
    display: flex;
    justify-content: space-between;
}
.modal__buttons--center {
    justify-content: center;
}
.modal__buttons--wide {
    justify-content: stretch;
    padding: 0 20px 20px 20px;
}
.modal__buttons .button {
    margin-right: 30px;
}
.modal__buttons--wide .button {
    flex: 1;
    text-align: center;
}
.modal__buttons .button:last-child {
    margin-right: 0;
}
.--rtl .modal__buttons .button {
    margin-right: 0;
    margin-left: 30px;
}
.modal__buttons .button:last-child {
    margin-left: 0;
    margin-right: 0;
}
.modal__favorite-categories {
    margin-bottom: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    overflow: hidden;
}
.modal__favorite-categories--shadow-top::before {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    height: 10px;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#40000000', endColorstr='#00000000', GradientType=0);
}
.modal__favorite-categories--shadow-bottom::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 10px;  
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#40000000', GradientType=0);
}
.modal__close {
    padding: 0;
    border: 0;
    width: 38px;
    height: 38px;
    background: transparent;
    font-size: 32px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    z-index: 10;
}
.modal__image-wrapper {
    margin-bottom: 20px;
}
.modal__image {
    display: block;
    width: 100%;
    position: relative;
}
.modal__image-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #fff;
    padding: 5px 10px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    color: #666;
}
.modal__search-result-partner {
    text-align: center;
    font-weight: 300;
    margin: 0 20px 20px;
    /*text-transform: uppercase;*/
    color: #002652;
    font-size: 24px;
}
.modal__search-result-title {
    color: #b48c24;
    font-size: 20px;
    text-align: center;
    font-weight: 300;
    margin: 0 20px 20px;
    /*text-transform: uppercase;*/
}

.map-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: transparent;

}
.map-search__content {
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.map-search__header {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #DDD;
    align-items: center;
}
.map-search__filters {
    display: flex;
    flex: 3;
    margin-right: 10px;
    max-width: 75%;
}
.--rtl .map-search__filters {
    margin-right: 0;
    margin-left: 10px;
}
.map-search__filters-close {
    display: none;
}
.map-search__filters-title {
    display: none;
}
.map-search__filter {
    flex: 1;
    margin-right: 10px;
    max-width: 25%;
    position: relative;
}
.--rtl .map-search__filter {
    margin-right: 0;
    margin-left: 10px;
}
.map-search__filter--query {
    max-width: initial;
}
.map-search__filter:last-child {
    margin-right: 0;
    margin-left: 0;
}
.map-search__filter .filter-multiselect__placeholder {
    margin-top: 10px;
    margin-left: 15px;
}
.--rtl .map-search__filter .filter-multiselect__placeholder {
    margin-left: 0;
    margin-right: 15px;
}
.map-search__filter .filter-multiselect__selected {
    font-size: 16px;
    padding: 0 35px 5px 5px;
    border-radius: 19px;
    flex-wrap: nowrap;
    overflow: hidden;
}
.map-search__filter .filter-multiselect__selected-item {
    border-radius: 14px;
    margin-right: 5px;
    margin-top: 5px;
    white-space: nowrap;
}
.map-search__filter .filter-multiselect__selected::before {
    position: absolute;
    content: '';
    top: 0;
    right: -1px;
    width: 35px;
    height: 38px;
    z-index: 1;
    border-top-right-radius: 19px;
    border-bottom-right-radius: 19px;
    background: -moz-linear-gradient(left, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 25%, rgba(238, 238, 238, 1) 99%, rgba(238, 238, 238, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 25%, rgba(238, 238, 238, 1) 99%, rgba(238, 238, 238, 1) 100%);
    background: linear-gradient(to right, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 25%, rgba(238, 238, 238, 1) 99%, rgba(238, 238, 238, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00eeeeee', endColorstr='#eeeeee', GradientType=1);
}
.map-search__filter .filter-multiselect__selected::after {
    top: 16px;
    right: 15px;
    z-index: 2;
}
.map-search__filter .filter-multiselect__options {
    padding: 5px 0;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
    max-height: 211px;
}
.map-search__filter .filter-multiselect__option {
    padding: 5px 20px;
}
.map-search__query {
    border: 1px solid #EEE;
    background: #EEE;
    border-radius: 19px;
    padding: 8px 15px 8px 46px;
    font-size: 16px;
    font-weight: 400;
    color: #002652;
    width: 100%;
}
.--rtl .map-search__query {
    padding: 8px 46px 8px 15px;
}
.map-search__query:focus {
    background: #FFF;
}
.map-search__query-icon {
    position: absolute;
    top: 10px;
    left: 15px;
    color: #666;
}
.--rtl .map-search__query-icon {
    left: auto;
    right: 15px;
}
.map-search__filter-button {
    display: none;
}
.map-search__close button {
    padding: 0;
    border: 0;
    width: 38px;
    height: 38px;
    background: transparent;
    font-size: 32px;
    cursor: pointer;
}
.map-search__body {
    flex: 1;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    position: relative;
}
.map-search__autocomplete {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    width: 500px;
    max-width: calc(100vw - 40px);
}
.map-search__autocomplete-input {
    border: 1px solid #FFF;
    background: #FFF;
    border-radius: 19px;
    padding: 8px 15px 8px 46px;
    font-size: 16px;
    font-weight: 400;
    color: #002652;
    width: 100%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}
.--rtl .map-search__autocomplete-input {
    padding: 8px 46px 8px 15px;
}
.map-search__autocomplete-icon {
    position: absolute;
    top: 10px;
    left: 15px;
    color: #666;
}
.--rtl .map-search__autocomplete-icon {
    left: auto;
    right: 15px;
}

.map-search__map {
    flex: 1;
    position: relative;
}

.pac-container {
    z-index: 9999;
}

.favorite-categories {
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.favorite-categories::-webkit-scrollbar {
    width: 5px;
}
.favorite-categories::-webkit-scrollbar-button {
    display: none;
}
.favorite-categories::-webkit-scrollbar-thumb {
    background: #CCC;
}
.favorite-categories__loading {
    display: flex;
    justify-content: center;
}
.favorite-categories__category {
    flex: 1 1 25%;
    max-width: 25%;
    display: flex;
    justify-content: center;
    padding: 10px;
}
.favorite-categories__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.favorite-categories__image-wrap {
    display: flex;
    width: 60px;
    height: 60px;
    border: 3px solid #ddd;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.favorite-categories__category--selected .favorite-categories__image-wrap,
.favorite-categories__image-wrap:hover {
    border-color: #b48c24;
}
.favorite-categories__image {
    display: block;
    width: 30px;
    height: 30px;
}
.favorite-categories__name {
    margin: 10px 0 0 0;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #002652;
}

.error-page {
    background: #FFF;
    width: 1000px;
    max-width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.error-page__icon {
    color: #EEE;
    margin-bottom: 30px;
}
.error-page__text {
    margin: 0;
    font-size: 20px;
    font-weight: 300;
    color: #333;
}
.error-page__text--center {
    text-align: center;
}
.error-page__buttons {
    text-align: center;
}

.card-preview {
    position: relative;
}
.card-preview__image {
    display: block;
    width: 100%;
}
.card-preview__text {
    position: absolute;
    color: #000;
    text-transform: uppercase;
    top: 120px;
    left: 45px;
    font-size: 22px;
    font-family: "NeoSansStd";
    font-weight: 400;
}

.accordion {
    margin-bottom: 20px;
}
.accordion:last-child {
    margin-bottom: 0;
}
.accordion__header {
    color: #333;
    background: #b48c24;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    cursor: pointer;
    background: #ddd;
    border: 1px solid #ddd;
}
.accordion--open .accordion__header {
    background: #fff;
    color: #b48c24;
}
.accordion__title {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
}
.accordion__icon {

}
.accordion__content {
    height: 0;
    overflow: hidden;
}
.accordion--open .accordion__content {
    height: auto;
    margin-top: 20px;
}

@media screen and (max-width: 1650px) {

    .logo__image {
        height: 100px;
        width: auto;
    }

    .user-menu {
        margin-top: 5px;
    }
    .user-menu__link,
    .user-menu__dropdown {
        font-size: 18px;
    }
    .user-menu__image {
        height: 20px;
        width: auto;
    }
    .user-menu__dropdown::after {
        top: 8px;
    }
    .user-menu__dropdown-items {
        top: 24px;        
    }

    .member-menu {
        margin-bottom: 5px;
    }
    .member-menu__link {
        font-size: 16px;
    }

    .hamad__text {
        font-size: 24px;
    }
    .hamad__input {
        font-size: 18px;
    }

    .slider {
        padding-bottom: 35px;
    }
    .slider__title {
        font-size: 42px;
    }
    .slider__name {
        font-size: 18px;
    }
    .slider__partner {
        font-size: 18px;
    }
    .slider__text {
        font-size: 16px;
    }
    .slider__discount {
        font-size: 32px;
    }
    .slider__currency {
        font-size: 16px;
    }
    .slider__arrow {
        width: auto;
        height: 60px;
    }

    .offer-page__redeem {
        display: block;
    }
    .offer-page__qr {
        margin: 0;
        display: flex;
        justify-content: center;
    }
    .offer-page__member {
        margin-bottom: 20px;
    }
    
    .voucher-page__qty {
        padding: 12px;
        font-size: 18px;
    }
    .voucher-page__qty-select-wrap::after {
        top: 20px;
    }
    .voucher-page__qty-select {
        padding: 12px 40px 12px 15px;
        font-size: 18px;
    }

    /*
    .button {
        font-size: 18px;
        padding: 12px 30px;
    }
    */
    .button__text--full {
        display: none;
    }
    .button__text--short {
        display: initial;
    }

    .footer__content-inner {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

}

@media screen and (max-width: 1450px) {

    .member-menu__link {
        font-size: 16px;
        padding: 8px 12px 7px;
    }

    .main-menu {
        padding: 15px 0;
    }
    .main-menu__item {
        padding-right: 15px;
    }
    .main-menu__link {
        margin-left: 15px;
    }
    .main-menu__link--active::after, .main-menu__link:hover::after {
        left: 15px;
        right: 15px;
    }

    .slider--services .swiper-slide {
        width: calc((100% - 30px) / 3.5);
    }
    .slider--offers .swiper-slide {
        width: calc((100% - 40px) / 4.5);
    }
    .slider--products .swiper-slide {
        width: calc((100% - 40px) / 4.5);
    }
    .slider--vouchers .swiper-slide {
        width: calc((100% - 40px) / 4.5);
    }

    .motto {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

}

@media screen and (max-width: 1350px) {

    .selection__items {
        width: 1000px;
    }
    .selection__item {
        flex: 1 0 25%;
        min-width: 25%;
        max-width: 25%;
    }

    .account__extra-offers-stats {
        display: block;
    }
    .account__extra-offers-stats-column {
        width: 100%;
        margin-right: 0;
        border-right: 0;
        padding-right: 0;
        margin-bottom: 10px;
    }
    .account__extra-offers-stats-column:last-child {
        margin-bottom: 0;
    }
    .account__extra-offers-stats-title {
        margin-bottom: 5px;
    }

}

@media screen and (max-width: 1250px) {

    .main-menu__item {
        padding-right: 10px;
    }
    .main-menu__link {
        margin-left: 10px;
        font-size: 16px;
    }
    .main-menu__link--active::after, .main-menu__link:hover::after {
        bottom: -3px;
        left: 10px;
        right: 10px;
    }

    .slider--offers .swiper-slide {
        width: calc((100% - 30px) / 3.5);
    }
    .slider--products .swiper-slide {
        width: calc((100% - 30px) / 3.5);
    }
    .slider--vouchers .swiper-slide {
        width: calc((100% - 30px) / 3.5);
    }

    .slider__title {
        font-size: 36px;
    }
    .slider__partner {
        padding-bottom: 5px;
        margin-bottom: 5px;
    }
    .slider__discount {
        font-size: 36px;
    }
    .slider__currency {
        font-size: 14px;
    }
    .slider__arrow {
        height: 60px;
    }

    .search-page__listing-row {
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    .search-page__result {
        min-width: calc((100% - 25px) / 2);
        margin-bottom: 25px;
        margin-right: 25px;
    }
    .--rtl .search-page__result {
        margin-right: 0;
        margin-left: 25px;
    }
    .search-page__result:nth-child(2n+0) {
        margin-right: 0;
    }
    .--rtl .search-page__result:nth-child(2n+0) {
        margin-left: 0;
    }

    .estore-page__listing--grid .estore-page__product {
        max-width: calc((100% - 30px) / 2);
    }
    .estore-page__listing--grid .estore-page__product:nth-child(2n+0) {
        margin-right: 0;
    }
    .--rtl .estore-page__listing--grid .estore-page__product:nth-child(2n+0) {
        margin-left: 0;
    }

    .signup__level {
        margin-right: 25px;
    }
    .--rtl .signup__level {
        margin-right: 0;
        margin-left: 25px;
    }
    .signup__level-description {
        display: none;
    }
    .signup__level--selected .signup__level-description {
        display: block;
    }

    .account__controls {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .account__controls-button {
        margin-bottom: 15px;
        margin-right: 0;
    }
    .account__controls-button:last-child {
        margin-bottom: 0;
    }

    .account-profile__form {
        width: 800px;
    }

    .all-categories__row {
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    .all-categories__category {
        margin-right: 25px;
        min-width: calc((100% - 25px) / 2);
        margin-bottom: 25px;
    }
    .--rtl .all-categories__category {
        margin-right: 0;
        margin-left: 25px;
    }
    .all-categories__category:nth-child(2n+0) {
        margin-right: 0;
    }
    .--rtl .all-categories__category:nth-child(2n+0) {
        margin-left: 0;
    }

    .selection__items {
        width: 800px;
    }

    .button__text--full {
        display: initial;
    }
    .button__text--short {
        display: none;
    }

}

@media screen and (max-width: 1050px) {

    .header__bottom {
        position: relative;
    }
    .header__bottom::before {
        position: absolute;
        top: 17px;
        left: 10px;
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #b48c24;
    }
    .header__bottom::after {
        position: absolute;
        top: 17px;
        right: 10px;
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #b48c24;
    }

    .hamad__bubble {
        min-width: auto;
        padding: 10px;
    }
    .hamad__text-link {
        font-size: 20px;
        /*margin-bottom: 5px;*/
    }
    .hamad__text-desc {
        font-size: 16px;
    }

    .main-menu__item {
        padding-right: 5px;
    }
    .main-menu__link {
        margin-left: 5px;
        font-size: 14px;
    }
    .main-menu__link--active::after, .main-menu__link:hover::after {
        bottom: 0;
        left: 5px;
        right: 5px;
    }

    .user-menu__item {
        margin-right: 15px;
        padding-left: 15px;
    }
    .user-menu__item:last-child {
        padding-right: 15px;
    }

    .slider__arrow {
        height: 40px;
    }

    .service-page {
        width: 90%;
    }

    .offer-page {
        width: 90%;
    }

    .voucher-page {
        width: 90%;
    }
    .voucher-page__pricing {
        flex-direction: column;
        align-items: flex-end;
    }
    .voucher-page__pricing-price {
        margin-right: 0;
        margin-bottom: 15px;
    }
    .--rtl .voucher-page__pricing-price {
        margin-left: 0;
    }

    .cms-page {
        width: 90%;
    }

    .search-page {
        width: 90%;
    }
    .search-page__filters {
        padding: 25px;
    }

    .estore-page {
        width: 90%;
    }
    .estore-page__filters {
        padding: 0;
    }

    .account {
        width: 90%;
    }

    .insurance {
        width: 90%;
    }

    .lease-to-own {
        width: 90%;
    }

    .temp-store {
        width: 90%;
    }

    .account-profile {
        width: 90%;
    }

    .prepaid-card__main {
        width: 90%;
    }

    .order-page {
        width: 90%;
    }

    .order-products__header {
        flex-wrap: wrap;
    }
    .order-products__header-item {
        min-width: 100%;
        text-align: center !important;
    }
    .order-products__header-item--no-flex {
        padding: 10px !important;
        min-width: 50%;
        max-width: 50%;
    }

    .order-product {
        flex-wrap: wrap;
    }
    .order-product__meta {
        min-width: calc(100% - 95px);
    }
    .order-product__qty {
        margin-left: 95px;
        text-align: left;
        padding-top: 0;
        min-width: calc(50% - 95px / 2);
    }
    .--rtl .order-product__qty {
        margin-left: 0;
        margin-right: 95px;
        text-align: right;
    }
    .order-product__subtotal {
        padding-top: 0;
        min-width: calc(50% - 95px / 2);
        text-align: right;
    }
    .--rtl .order-product__subtotal {
        text-align: left;
    }

    .section__columns {
        display: block;
    }

    .section__column {
        width: 100%;
        margin-right: 0;
        margin-bottom: 25px;
    }
    .--rtl .section__column {
        margin-left: 0;
    }
    .section__column:last-child {
        margin-bottom: 0;
    }

    .selection__items {
        width: 100%;
        padding: 0 50px;
    }
    .selection__item {
        flex: 1 0 33.333%;
        min-width: 33.333%;
        max-width: 33.333%;
    }

    .footer__content-inner {
        flex-direction: column;
        align-items: stretch;
    }
    .footer__more-links {
        flex: 1;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .--rtl .footer__more-links {
        margin-left: 0;
    }
    .footer__location {
        flex: 1;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .--rtl .footer__location {
        margin-left: 0;
    }
    .footer__sns-apps {
        flex: 1;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .--rtl .footer__sns-apps {
        margin-left: 0;
    }
    .footer__sns {
        justify-content: center;
    }
    .footer__sns-name {
        text-align: center;
    }
    .footer__apps-title {
        text-align: center;
    }
    .footer__apps-stores {
        align-items: center;
    }
    .footer__iso {
        justify-content: center;
    }

    .footer-section__title {
        text-align: center;
        display: block;
    }
    .footer-section__title::after {
        margin-left: 0;
        position: relative;
        top: -10px;
    }
    .--rtl .footer-section__title::after {
        margin-right: 0;
    }
    .footer-section__title-text {
        padding: 0 10px;
        z-index: 1;
        position: relative;
    }
    .footer-section__menu {
        text-align: center;
    }
    .footer-section__text {
        text-align: center;
    }

}

@media screen and (max-width: 900px) {

    .logo {
        margin-left: 20px;
    }
    .logo__image {
        height: 60px;
    }
    .logo__image--wide {
        display: none;
    }
    .logo__image--narrow {
        display: block;
    }

    .header__menus {
        display: none;
    }

    .header__hamad {
        justify-content: stretch;
        padding-top: calc((2 / 3) * 100%);
    }
    .header__hamad--no-news {
        padding: 0;
    }

    .hamad {
        flex: 1;
        width: calc(100% - 40px);
    }
    .header__hamad--no-news .hamad {
        flex: none;
        margin: 0 20px;
    }
    .hamad__bubble {
        padding: 10px 10px 5px 10px;
        border-radius: 15px;
    }
    .hamad__bubble::after {
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        bottom: -23px;
    }
    .hamad__text {
        font-size: 18px;
    }
    .hamad__link {
        font-size: 14px;
    }
    .hamad__input {
        min-width: auto;
        font-size: 18px;
        padding: 14px 85px 14px 20px;
    }

    .hamad-news__image {
        display: none;
    }
    .hamad-news__image--mobile {
        display: block;
    }

    .header__mobile {
        display: flex;
        align-items: flex-start;
        position: absolute;
        top: 32px;
        right: 20px;
    }
    .--rtl .header__mobile {
        right: auto;
        left: 20px;
    }
    .header__mobile-cart {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 300;
        color: #fff;
        font-size: 20px;
        text-decoration: none;
        margin-right: 20px;
    }
    .--rtl .header__mobile-cart {
        margin-right: 0;
        margin-left: 20px;
    }
    .header__mobile-cart-image {
        height: 26px;
        width: auto;
    }
    .header__mobile-cart-count {
        margin-left: 5px
    }
    .--rtl .header__mobile-cart-count {
        margin-left: 0;
        margin-right: 5px;
    }

    .hamburger {
        border: 0;
        padding: 0;
        background: transparent;
        cursor: pointer;
    }
    .hamburger__line {
        display: block;
        margin-bottom: 4px;
        width: 40px;
        height: 6px;
        background: #fff;
    }
    .hamburger__line:last-child {
        margin-bottom: 0;
    }

    .menu-toggle-user {
        border: 0;
        padding: 0;
        background: transparent;
        cursor: pointer;
        margin-right: 20px;
    }
    .--rtl .menu-toggle-user {
        margin-right: 0;
        margin-left: 20px;
    }
    .menu-toggle-user__image {
        display: block;
    }

    .menu-toggle-map-search {
        border: 0;
        padding: 0;
        background: transparent;
        cursor: pointer;
        margin-right: 20px;
    }
    .--rtl .menu-toggle-map-search {
        margin-right: 0;
        margin-left: 20px;
    }
    .menu-toggle-map-search__image {
        display: block;
    }

    .header__mobile-search {
        margin-right: 20px;
    }
    .--rtl .header__mobile-search {
        margin-right: 0;
        margin-left: 20px;
    }
    .header__mobile-search__image {
        display: block;
    }

    .header__menus {
        display: block;
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        z-index: 10;
        background: #FFF;
        margin-right: 0;
        padding: 20px;
        box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
        visibility: hidden;
        pointer-events: none;
        transition: all 0.2s ease-in;
        opacity: 0;
    }
    .--rtl .header__menus {
        margin-left: 0;
    }
    .header__menus--open {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
    }

    .header__menu {
        display: block;
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        z-index: 10;
        box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
        visibility: hidden;
        pointer-events: none;
        transition: all 0.2s ease-in;
        opacity: 0;
    }
    .header__menu--open {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
    }
    
    .main-menu {
        padding: 20px;
    }
    .main-menu__list {
        flex-direction: column;
    }
    .main-menu__item {
        margin: 0 0 10px 0;
        padding-right: 0;
    }
    .--rtl .main-menu__item {
        padding-left: 0;
    }
    .main-menu__item:first-child {
        display: none;
    }
    .main-menu__item:last-child {
        margin-bottom: 0;
    }
    .main-menu__item::before {
        display: none;
    }
    .main-menu__link {
        color: #002753;
        font-size: 24px;
        font-weight: 300;
        margin: 0;
    }
    .--rtl .main-menu__link {
        margin: 0;
    }
    .main-menu__link--active::after, .main-menu__link:hover::after {
        display: none;
    }

    .user-menu {
        display: block;
        margin-bottom: 30px;
        margin-top: 0;
    }
    .user-menu__item {
        margin-right: 0;
        padding-left: 0;
        margin-bottom: 10px;
    }
    .--rtl .user-menu__item {
        margin-left: 0;
        padding-right: 0;
    }
    .user-menu__item:last-child {
        margin-bottom: 0;
    }
    .user-menu__item::before {
        display: none;
    }
    .user-menu__item:last-child::after {
        display: none;
    }
    .user-menu__item--cart {
        display: none;
    }
    .user-menu__item--hide-mobile {
        display: none;
    }
    .user-menu__link {
        color: #002753;
        display: flex;
        align-items: center;
        /*flex-direction: row-reverse;*/
        font-size: 24px;
    }
    .user-menu__image {
        /*margin-left: 10px;*/
        margin-right: 10px;
    }
    .--rtl .user-menu__image {
        margin-right: 0;
        margin-left: 10px;
    }
    .user-menu__cart::before {
        content: '(';
    }
    .user-menu__cart::after {
        content: ')';
    }
    .user-menu__item:last-child {
        padding-right: 0;
    }
    .--rtl .user-menu__item:last-child {
        padding-left: 0;
    }
    .user-menu__dropdown {
        color: #002652;
        margin-right: 30px;
    }
    .--rtl .user-menu__dropdown {
        margin-right: 0;
        margin-left: 30px;
    }
    .user-menu__dropdown::after {
        border-left: 5px solid #002652;
        border-right: 5px solid transparent;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        top: 5px;
    }
    .--rtl .user-menu__dropdown::after {
        border-left: 5px solid transparent;
        border-right: 5px solid #002652;
    }
    .user-menu__dropdown-items {
        display: block;
        position: static;
        padding: 0;
        display: flex;
    }
    .user-menu__dropdown-item {
        padding: 5px 10px;
        margin-right: 5px;
        border-radius: 14px;
    }
    .--rtl .user-menu__dropdown-item {
        margin-right: 0;
        margin-left: 5px;
    }
    .user-menu__dropdown-item:last-child {
        margin-right: 0;
    }
    .--rtl .user-menu__dropdown-item:last-child {
        margin-left: 0;
    }

    .member-menu {
        display: block;
        margin-bottom: 0;
    }
    .member-menu__item {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .--rtl .member-menu__item {
        margin-left: 0;
    }
    .member-menu__item:last-child {
        margin-bottom: 0;
    }
    .user-menu__image--white {
        display: none;
    }
    .user-menu__image--blue {
        display: initial;
    }
    .user-menu__mobile-text {
        display: inline-block;
    }

    .slider--services .swiper-slide {
        width: calc((100% - 10px) / 1.5);
    }
    .slider--offers .swiper-slide {
        width: calc((100% - 10px) / 1.5);
    }
    .slider--products .swiper-slide {
        width: calc((100% - 10px) / 1.5);
    }
    .slider--vouchers .swiper-slide {
        width: calc((100% - 10px) / 1.5);
    }

    .slider__header {
        padding: 0;
        overflow: hidden;
        justify-content: space-between;
    }
    .slider__title {
        margin-right: 20px;
        overflow: hidden;
    }
    .slider__title::after {
        display: none;
    }
    .slider__title-text {
        font-size: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .slider__button {
        padding: 6px 10px 5px;
        font-size: 14px;
    }

    .section {
        padding-bottom: 35px;
    }
    .section__title {
        font-size: 36px;
    }

    .service-page {
        width: 100%;
        padding: 25px;
        display: block;
    }
    .service-page__gallery {
        margin-right: 0;
        margin-bottom: 25px;
    }
    .--rtl .service-page__gallery {
        margin-left: 0;
    }

    .offer-page {
        width: 100%;
        padding: 25px;
        display: block;
    }
    .offer-page__main {
        display: block;
    }
    .offer-page__gallery {
        margin-right: 0;
        margin-bottom: 25px;
    }
    .--rtl .offer-page__gallery {
        margin-left: 0;
    }
    .offer-page__extended {
        margin-top: 25px;
    }

    .voucher-page {
        width: 100%;
        padding: 25px;
        display: block;
    }
    .voucher-page__main {
        display: block;
    }
    .voucher-page__gallery {
        margin-right: 0;
        margin-bottom: 25px;
    }
    .--rtl .voucher-page__gallery {
        margin-left: 0;
    }
    .voucher-page__extended {
        margin-top: 25px;
    }

    .cms-page {
        width: 100%;
        padding: 25px;
    }
    .cms-page__iframe-loader {
        top: 25px;
    }

    .signup {
        width: 100%;
        padding: 25px;
    }
    .signup__levels {
        display: block;
    }
    .signup__level {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .--rtl .signup__level {
        margin-left: 0;
    }
    .signup__level:last-child {
        margin-bottom: 0;
    }

    .renew {
        width: 100%;
        padding: 25px;
    }

    .upgrade {
        width: 100%;
        padding: 25px;
    }

    .signin {
        display: block;
        padding: 25px;
        width: 100%;
    }
    .signin__form {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 30px;
    }
    .--rtl .signin__form {
        margin-left: auto;
        margin-right: auto;
    }
    .signin__options {
        padding-left: 0;
        padding-top: 30px;
        border-left: none;
        border-top: 1px solid #002652;
        justify-content: center;
    }
    .--rtl .signin__options {
        padding-right: 0;
        border-right: none;
    }
    .signin__options-or {
        top: 0;
        left: 50%;
        translate: -50% -50%;
    }
    .--rtl .signin__options-or {
        left: 50%;
        right: auto;
        translate: -50% -50%;
    }

    .forgot {
        padding: 25px;
    }

    .search-page {
        width: 100%;
    }

    .estore-page {
        width: 100%;
    }

    .order-page {
        width: 100%;
        padding: 25px;
    }

    .account {
        width: 100%;
        padding: 25px;
    }

    .insurance {
        width: 100%;
        padding: 0 25px 25px;
    }

    .lease-to-own {
        width: 100%;
        padding: 0 25px 25px;
    }

    .temp-store {
        width: 100%;
        padding: 0 25px 25px;
    }

    .account-password {
        width: 100%;
        padding: 25px;
    }

    .account-profile {
        width: 100%;
        padding: 25px;
    }

    .prepaid-card__main {
        width: 100%;
        padding: 25px;
    }
    .prepaid-card__gate {
        padding: 25px;
    }
    .prepaid-card__gate-form {
        width: 100%;
    }
    .prepaid-card__transfer {
        padding: 25px;
    }
    .prepaid-card__transfer-form {
        width: 100%;
    }
    .prepaid-card__filters {
        flex-direction: column;
    }
    .prepaid-card__filter {
        margin-right: 0;
        max-width: 100%;
    }

    .chat {
        width: 100%;
        padding: 25px;
    }

    .unlink { 
        width: 100%;
        padding: 25px;
    }

    .cancel-membership { 
        width: 100%;
        padding: 25px;
    }

    .all-categories__category {
        margin-right: 0;
        min-width: 100%;
    }
    .--rtl .all-categories__category {
        margin-left: 0;
    }

    .form__row {
        display: block;
    }
    .form__column--half {
        max-width: 100%;
        width: 100%;
    }
    .form__column--quarter {
        max-width: 50%;
        width: 50%;
    }
    .form__column--button-only {
        padding-top: 0;
        text-align: center;
    }

    .payment-request {
        padding: 25px;
    }

    .cart {
        padding: 25px;
    }
    .cart__header-subtotal {
        display: none;
    }
    .cart__header-price {
        display: none;
    }
    .cart__header-qty {
        display: none;
    }
    .cart__row {
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    .cart__image-wrapper {
        margin-bottom: 10px;
    }
    .cart__description {
        margin-right: 0;
        margin-bottom: 10px;
        min-width: calc(100% - 120px);
    }
    .--rtl .cart__description {
        margin-left: 0;
        margin-bottom: 10px;
        min-width: calc(100% - 120px);
    }
    .cart__price {
        flex: 1;
        width: auto;
        max-width: initial;
    }
    .cart__subtotal {
        flex: 1;
        width: auto;
    }
    .cart__total-shipping,
    .--rtl .cart__total-shipping {
        text-align: center;
        margin: 0;
    }
    .cart__total-fee,
    .--rtl .cart__total-fee {
        text-align: center;
        margin: 0;
    }
    .cart__total-amount,
    .--rtl .cart__total-amount {
        text-align: center;
        margin: 0;
    }

    .selection__items {
        width: 100%;
        padding: 0;
    }
    .selection__item {
        flex: 1 0 50%;
        min-width: 50%;
        max-width: 50%;
    }

    .map-search__filters {
        position: fixed;
        width: 100vw;
        max-width: initial;
        top: 0;
        left: -100vw;
        height: 100vh;
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        background: #fff;
        z-index: 10;
        margin: 0;
        display: block;
        transition: all 0.2s ease-in;
    }
    .--rtl .map-search__filters {
        left: auto;
        right: -100vw;
    }
    .map-search__filters--open {
        left: 0;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    }
    .--rtl .map-search__filters--open {
        left: auto;
        right: 0;
    }
    .map-search__filters-close {
        display: block;
        padding: 0;
        border: 0;
        width: 38px;
        height: 38px;
        background: transparent;
        font-size: 32px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        color: #000;
        z-index: 10;
    }
    .--rtl .map-search__filters-close {
        right: auto;
        left: 10px;
    }
    .map-search__filters-title {
        display: block;
        color: #002652;
        font-size: 28px;
        font-weight: 300;
        padding: 13px 10px;
        margin: 0 0 20px 0;
        text-transform: uppercase;
        border-bottom: 1px solid #DDD;
    }
    .map-search__filters .map-search__filter {
        margin: 0 10px 20px 10px;
        max-width: initial;
    }
    .map-search__filters .map-search__filter:last-child {
        margin-bottom: 0;
        margin-right: 10px;
        margin-left: 10px;
    }
    .map-search__filter-button {
        display: block;
    }
    .map-search__filter-button button {
        padding: 0;
        border: 0;
        height: 38px;
        background: transparent;
        font-size: 16px;
        cursor: pointer;
        background: #eee;
        padding: 0 15px;
        font-weight: 400;
        border-radius: 19px;
    }
}

@media screen and (max-width: 650px) {

    .hamad__icon-wrap {
        margin-right: 30px;
    }
    .--rtl .hamad__icon-wrap {
        margin-right: 0;
        margin-left: 30px;
    }
    .hamad__icon {
        width: 100px;
        height: 100px;
    }
    .hamad__image {
        width: 65px;
        height: auto;
    }
    .hamad__bubble::before {
        left: -20px;
    }
    .--rtl .hamad__bubble::before {
        left: auto;
        right: -20px;
    }
    .hamad__text {
        font-size: 14px;
    }

    .slider--services .swiper-slide {
        width: 100%;
    }
    .slider--offers .swiper-slide {
        width: 100%;
    }
    .slider--products .swiper-slide {
        width: 100%;
    }
    .slider--vouchers .swiper-slide {
        width: 100%;
    }

    .service-page__gallery {
        margin-left: -25px;
        margin-top: -25px;
        margin-right: -25px;
    }
    .offer-page__gallery {
        margin-left: -25px;
        margin-top: -25px;
        margin-right: -25px;
    }
    .voucher-page__gallery {
        margin-left: -25px;
        margin-top: -25px;
        margin-right: -25px;
    }

    .hamad-news__arrow {
        display: none;  
    }

    .categories {
        padding: 20px;
        margin-bottom: 10px;
    }
    .categories__image-wrap {
        width: 80px;
        height: 80px;
        border: 3px solid #d5d9df;
    } 
    .categories__image {
        display: block;
        width: 50px;
        height: 50px;
    }
    .categories__name {
        margin: 10px 0 0 0;
        font-size: 16px;
    }
    .categories__arrow-image {
        display: none;
    }

    .signup-progress__text {
        font-size: 10px;
    }

    .renew__level {
        display: block;
    }

    .search-page__filters {
        display: block;
    }
    .search-page__filter {
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
    }
    .--rtl .search-page__filter {
        margin-left: 0;
    }
    .search-page__filter:last-child {
        margin-bottom: 0;
    }
    .search-page__result {
        min-width: 100%;
        margin-right: 0;
    }
    .--rtl .search-page__result {
        margin-left: 0;
    }

    .estore-page__listing--grid .estore-page__product {
        min-width: 100%;
        margin-right: 0;
    }
    .--rtl .estore-page__listing--grid .estore-page__product {
        margin-left: 0;
    }

    .estore-page__listing-header {
        flex-wrap: wrap;
    }
    .estore-page__listing-title {
        min-width: 100%;
        margin-bottom: 10px;
    }
    .estore-page__listing-controls {
        margin-left: auto;
    }
    .--rtl .estore-page__listing-controls {
        margin-left: initial;
        margin-right: auto;
    }
    .estore-page__listing-control-type {
        display: none;
    }

    .estore-page__listing--list .estore-page__product-link {
        display: block;
    }
    .estore-page__listing--list .estore-page__product-images {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 30px;
    }

    .form__column--quarter {
        max-width: 100%;
        width: 100%;
    }

    .cart__controls {
        flex-direction: column;
    }
    .cart__controls .button {
        width: 100%;
        display: block;
    }
    .cart__controls .button:first-child {
        margin-bottom: 20px;
    }

    .order-details {
        flex-wrap: wrap;
    }
    .order-details__item {
        min-width: 50%;
        max-width: 50%;
    }

    .order-voucher__header {
        flex-wrap: wrap;
    }
    .order-voucher__header-item {
        min-width: 100%;
        max-width: 100%;
    }
    .order-voucher__header-item--no-flex {
        padding-left: 10px;
        min-width: 50%;
        max-width: 50%;
        text-align: left;
    }
    .--rtl .order-voucher__header-item--no-flex {
        padding-right: 10px;
    }
    .order-voucher__voucher-item {
        flex-wrap: wrap;
    }
    .order-voucher__pdf {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .voucher-page__extended {
        margin-left: -25px;
        margin-right: -25px;
        margin-bottom: -25px;
    }

    .offer-page__extended {
        margin-left: -25px;
        margin-right: -25px;
        margin-bottom: -25px;
    }

    .react-tabs__tab {
        padding: 15px 15px;
        font-size: 16px;
        width: 50%;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        text-align: center;
    }
    .react-tabs__tab-panel {
        border: 0;
    }
    .react-tabs__tab-panel--map {
        padding: 0;
    }

    .selection__items {
        padding: 0 15px;
    }
    .selection__circle {
        width: 100px;
        height: 100px;
    }
    .selection__icon {
        width: 75px;
        height: 75px;
    }
    .selection__title {
        font-size: 16px;
    }

    .form__buttons {
        flex-direction: column;
    }
    .form__button {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .form__button:last-child {
        margin-bottom: 0;
    }

    .motto__title {
        max-width: initial;
        padding: 0 20px;
    }
    .motto__title-text {
        padding: 0 10px;
        font-size: 28px;
    }
    .motto__text {
        font-size: 18px;
    }

    .favorite-categories__category {
        flex: 1 1 33.333%;
        max-width: 33.333%;
    }

    .copyright {
        flex-direction: column;
    }
    .copyright__text {
        text-align: center;
        margin-bottom: 20px;
    }
    .copyright__links {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
        text-align: center;
    }
    .copyright__link {
        display: block;
        margin-bottom: 5px;
        margin-left: 0;
        margin-right: 0;
    }
    .copyright__link:last-child {
        margin-bottom: 0;
    }

    .modal--search-result {
        overflow-y: auto;
        display: block;
    }
    .modal__content--search-result {
        min-height: 100vh;
        width: 100%;
        max-width: 100%;
        max-height: initial;
    }

}

.react-datepicker-wrapper {
    display: block;
}
.react-datepicker__input-container {
    display: block;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: #b48c24;
    font-weight: 400;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range,
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover {
    background-color: #b48c24;
}
